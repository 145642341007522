import { format, getTime, formatDistanceToNow, parse } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function getCurrentDate(dateFormat: string) {
  const currentDate = moment();
  const formattedDate = currentDate.format(dateFormat);
  return formattedDate;
}

export function datePickerDisplayDate(date: any, newFormat?: string) {
  if (!date || date === 'Invalid date') {
    return '';
  }
  const fm = newFormat || 'YYYY-MM-DD';
  return moment(date, fm).toDate();
}

export function datePickerEditFormDate(date: any, newFormat?: string) {
  if (!date || date === 'Invalid date') {
    return '';
  }
  const fm = newFormat || 'DD-MM-YYYY';
  return moment(date, fm).toDate();
}

export function formateActivityDatePicker(date: any) {
  return moment(date).toDate();
}

export function fDateToMMMM_YYYY(date: any) {
  return moment(date).format('MMMM YYYY');
}

export function formatDateForDatepicker(dateObject: any, newFormat?: string) {
  if (dateObject === '') {
    return '';
  }
  const fm = newFormat || 'YYYY-MM-DD';
  const formattedDate = moment(dateObject).format(fm);
  if (formattedDate === 'Invalid date') {
    return '';
  }
  return formattedDate;
}
export function formatDateForDatepickerShort(dateObject: any, newFormat?: string) {
  if (dateObject === '') {
    return '';
  }
  const fm = newFormat || 'DD/MM/YYYY';
  const formattedDate = moment(dateObject).format(fm);
  if (formattedDate === 'Invalid date') {
    return '';
  }
  return formattedDate;
}

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';
  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';
  if (date) {
    if (format(new Date(date), 'hh') < '10') {
      return `${format(new Date(date), 'dd MMM yyyy')} 0${format(new Date(date), 'p')}`;
    }
  }
  return date ? format(new Date(date), fm) : '';
}
export function fDateTimes(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'yyyy-MM-dd HH:mm:ss';

  return date ? format(new Date(date), fm) : '';
}
export function fTimes(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'HH:mm:ss';

  return date ? format(new Date(date), fm) : '';
}
export function fTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'hh:mm aaa';

  return date ? format(new Date(date), fm) : '';
}
export function fDays(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM';

  return date ? format(new Date(date), fm) : '';
}
export function fDay(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dddd';

  return date ? format(new Date(date), fm) : '';
}

export function fDateRange(date: any, newFormat?: string) {
  const fm = newFormat || 'yyyy-MM-dd';
  const DateRange = {
    startDate: format(new Date(date[0]), fm),
    endDate: date[1] ? format(new Date(date[1]), fm) : false,
  };
  return date ? DateRange : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function convertTimeToFormattedString(inputTime: string) {
  const [hours, minutes] = inputTime.split(':').map(Number);
  const formattedTime = `${hours}h ${minutes}m`;
  return formattedTime;
}

export const timeDuration = (timeString: any) => {
  let parsedTime;
  if (timeString.includes(':')) {
    parsedTime = moment(timeString, 'HH:mm:ss');
  } else {
    parsedTime = moment(`${timeString}:00`, 'HH:mm:ss');
  }
  return `Duration: ${parsedTime.format('HH:mm')}`;
};

export function timePickerDisplayTime(date: any, newFormat?: string) {
  if (!date || date === 'Invalid date') {
    return '';
  }
  const fm = newFormat || 'YYYY-MM-DD';
  return moment(date).toDate();
}

export const timeForTimePickerPayload = (date: any, newFormat?: string) => {
  if (!date || date === 'Invalid date') {
    return '';
  }
  const fm = newFormat || 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
  return moment.utc(date).format(fm);
};

export function replaceDateInTimeString(dateString: string, timeString: string) {
  const dateInDateString = moment(dateString).format('YYYY-MM-DD');
  const dateInTimeString = moment(timeString).format('YYYY-MM-DD');

  if (dateInDateString === dateInTimeString) {
    return timeString;
  }
  const newDate = moment(dateString).format('YYYY-MM-DD');
  const newTime = moment(timeString).format('HH:mm:ss.SSS[Z]');
  return `${newDate}T${newTime}`;
}

export function monthYearForSalarySlip(dateString: string) {
  const date = moment(dateString, 'YYYY-MM');
  if (date.isValid()) {
    return date.format('MMMM YYYY');
  }
  return '';
}

export function currentMonthDate(fm: string) {
  return moment().format(fm || 'YYYY-MM');
}
