/* eslint-disable class-methods-use-this */
import { adminEndPoint, permissionEndPoint } from 'src/api/api-end-points';
import commonAPI from '../../../api/common';

class PermissionService {
  get() {
    return commonAPI.get(`${permissionEndPoint}`);
  }

  getById(id: any) {
    return commonAPI.get(`${permissionEndPoint}/${adminEndPoint}/${id}`);
  }

  put(id: any, data: any) {
    return commonAPI.put(`${permissionEndPoint}/${id}`, data);
  }
}

export default new PermissionService();
