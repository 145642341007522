import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getAssets } from '../thunk/get/getAssets';
import { GET_ASSETS } from './getSlices/getAssets.get';
import { getAssetById } from '../thunk/get/getAssetById.thunk';
import { GET_ASSETS_BY_ID } from './getSlices/getAssetsById.get';

export const AssetsSlice = createSlice({
  name: 'AssetsSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET ASSETS
    [`${getAssets.pending}`]: GET_ASSETS.pending,
    [`${getAssets.fulfilled}`]: GET_ASSETS.fulfilled,
    [`${getAssets.rejected}`]: GET_ASSETS.rejected,

    // GET ASSET BY ID
    [`${getAssetById.pending}`]: GET_ASSETS_BY_ID.pending,
    [`${getAssetById.fulfilled}`]: GET_ASSETS_BY_ID.fulfilled,
    [`${getAssetById.rejected}`]: GET_ASSETS_BY_ID.rejected,
  },
});

export default AssetsSlice.reducer;
