/* eslint-disable class-methods-use-this */
import { blogStatusMasterEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class BlogStatusService {
  get(queryParams: any) {
    return commonAPI.get(`/${blogStatusMasterEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${blogStatusMasterEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${blogStatusMasterEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${blogStatusMasterEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${blogStatusMasterEndPoint}/${id}`);
  }
}

export default new BlogStatusService();
