import { InitialState } from '../type';

export const POST_HOLIDAY = {
  pending: (state: InitialState, action: any) => {
    state.post.isLoading = true;
    state.post.isSuccess = false;
    state.post.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.post.errorMessage = action.payload.code;
    state.post.isLoading = false;
    state.post.isSuccess = true;
    state.post.isError = false;
  },
  rejected: (state: InitialState, action: any) => {
    state.post.isLoading = false;
    state.post.isSuccess = false;
    state.post.isError = true;
    state.post.errorMessage = action?.payload?.data?.message;
  },
};
