/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { assetTypeAdminEndPoint, assetTypeEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class AssetsTypeService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${assetTypeAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${assetTypeEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${assetTypeEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${assetTypeEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${assetTypeEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${assetTypeEndPoint}/${id}`);
  }
}

export default new AssetsTypeService();
