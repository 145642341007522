import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_EMPLOYMENT_STATUS } from './get/getEmploymentStatus.get';
import { GET_EMPLOYMENT_STATUS_BY_ID } from './get/getEmploymentStatusById.get';
import { getEmploymentStatus } from '../thunk/get/getEmploymentStatus.thunk';
import { getEmploymentStatusById } from '../thunk/get/getEmploymentStatusById.thunk';

export const EmploymentStatusSlice = createSlice({
  name: 'EmploymentStatusSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Employment Status
    [`${getEmploymentStatus.pending}`]: GET_EMPLOYMENT_STATUS.pending,
    [`${getEmploymentStatus.fulfilled}`]: GET_EMPLOYMENT_STATUS.fulfilled,
    [`${getEmploymentStatus.rejected}`]: GET_EMPLOYMENT_STATUS.rejected,
    // GET Employment Status BY ID
    [`${getEmploymentStatusById.pending}`]: GET_EMPLOYMENT_STATUS_BY_ID.pending,
    [`${getEmploymentStatusById.fulfilled}`]: GET_EMPLOYMENT_STATUS_BY_ID.fulfilled,
    [`${getEmploymentStatusById.rejected}`]: GET_EMPLOYMENT_STATUS_BY_ID.rejected,
  },
});

export default EmploymentStatusSlice.reducer;
