import { InitialState } from '../type';

export const GET_ASSETS_BY_ID = {
  pending: (state: InitialState, action: any) => {
    state.get.isLoading = true;
    state.get.isSuccess = false;
    state.get.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.get.totalItems = action.payload?.data?.data?.totalItems;
    state.get.assetsById = action.payload?.data?.data;
    state.get.isLoading = false;
    state.get.isSuccess = true;
    state.get.isError = false;
  },

  rejected: (state: InitialState, action: any) => {
    state.get.isLoading = false;
    state.get.isSuccess = false;
    state.get.isError = true;
  },
};
