import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginEndPoint } from 'src/api/api-end-points';
import { PATH_AFTER_LOGIN } from 'src/utils/const';
import { setSession } from 'src/auth/context/jwt/utils';
import authService from '../../services/auth.service';

interface LoginPayload {
  email: string;
  password: string;
  token?: string;
}

export const loginUser = createAsyncThunk('auth-login', async (payload: LoginPayload, thunkAPI) => {
  try {
    const response = await authService.post(loginEndPoint, payload);
    if (response.data.code === 200) {
      setSession(response.data.data.token);
      window.location.href = PATH_AFTER_LOGIN;
      return response.data;
    }
    return thunkAPI.rejectWithValue(response.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const setLoginData = createAsyncThunk('set-login-data', async (payload: any, thunkAPI) => {
  try {
    return payload;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
