import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getSalarySlip } from '../thunk/get/getSalary.thunk';
import { GET_SALARYSLIP } from './get/getSalarySlip.get';
import { clearSalarySlipById, getSalarySlipById } from '../thunk/get/getSalaryById.thunk';
import { GET_SALARYSLIP_BY_ID } from './get/getSalarySlipById.get';
import { getSalaryCalculation } from '../thunk/get/getSalaryCalculation';

export const SalarySlipSlice = createSlice({
  name: 'SalarySlipSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET salary slip
    [`${getSalarySlip.pending}`]: GET_SALARYSLIP.pending,
    [`${getSalarySlip.fulfilled}`]: GET_SALARYSLIP.fulfilled,
    [`${getSalarySlip.rejected}`]: GET_SALARYSLIP.rejected,

    // GET salary slip BY ID
    [`${getSalarySlipById.pending}`]: GET_SALARYSLIP_BY_ID.pending,
    [`${getSalarySlipById.fulfilled}`]: GET_SALARYSLIP_BY_ID.fulfilled,
    [`${getSalarySlipById.rejected}`]: GET_SALARYSLIP_BY_ID.rejected,

    // GET salary slip calculation
    [`${getSalaryCalculation.pending}`]: GET_SALARYSLIP_BY_ID.pending,
    [`${getSalaryCalculation.fulfilled}`]: GET_SALARYSLIP_BY_ID.fulfilled,
    [`${getSalaryCalculation.rejected}`]: GET_SALARYSLIP_BY_ID.rejected,

    // GET salary slip BY ID
    [`${clearSalarySlipById.pending}`]: GET_SALARYSLIP_BY_ID.pending,
    [`${clearSalarySlipById.fulfilled}`]: GET_SALARYSLIP_BY_ID.fulfilled,
    [`${clearSalarySlipById.rejected}`]: GET_SALARYSLIP_BY_ID.rejected,
  },
});

export default SalarySlipSlice.reducer;
