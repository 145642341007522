import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getColor } from '../thunk/get/getColor.thunk';
import { getColorById } from '../thunk/get/getColorById.thunk';
import { GET_COLOR } from './get/getColor.get';
import { GET_COLOR_BY_ID } from './get/getColorById.get';

export const ColorSlice = createSlice({
  name: 'ColorSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Blog Category
    [`${getColor.pending}`]: GET_COLOR.pending,
    [`${getColor.fulfilled}`]: GET_COLOR.fulfilled,
    [`${getColor.rejected}`]: GET_COLOR.rejected,
    // GET Blog Category BY ID
    [`${getColorById.pending}`]: GET_COLOR_BY_ID.pending,
    [`${getColorById.fulfilled}`]: GET_COLOR_BY_ID.fulfilled,
    [`${getColorById.rejected}`]: GET_COLOR_BY_ID.rejected,
  },
});

export default ColorSlice.reducer;
