import { createSlice } from '@reduxjs/toolkit';
import { getEmployee } from '../thunk/get/getEmployee.thunk';
import { initialState } from './initialState';
import { clearEmployeeByIdState, getEmployeeById } from '../thunk/get/getEmpById.thunk';
import { getCurrentEmployeeById } from '../thunk/get/getCurEmpById.thunk';
import { GET_EMPLOYEE } from './get-slices/getEmployee.get';
import { GET_EMPLOYEE_BY_ID } from './get-slices/getEmpById.get';
import { GET_CURRENT_EMPLOYEE_BY_ID } from './get-slices/getCurEmpById.get';
import { saveCreateEmployeeDetails } from '../thunk/post/saveCreateEmployeeDetails';
import { SAVE_EMPLOYEE_DETAILS } from './post-slices/saveEmployeeDetails.post';
import { EMPLOYEE_PAYLOAD } from './post-slices/employeePayload';
import { saveEmployeePayload } from '../thunk/post/saveEmployeePayload.thunk';
import { putEmployee } from '../thunk/put/putEmployee';
import { UPDATED_EMPLOYEE } from './put-slices/updatedEmployee.put';

export const employeeSlice: any = createSlice({
  name: 'employeeSlice',
  initialState,
  reducers: {
    employeeIdChange(state: any, action: any) {
      state.empId = action.payload.empId;
      state.changeFlag = action.payload.changeFlag;
    },
    isSameAddress(state: any, action: any) {
      state.notSame = action.payload;
    },
    teamLeadChange(state: any, action: any) {
      state.TeamLeadState = action.payload;
    },
    currentCitiesChange(state: any, action: any) {
      state.CurrentCities = action.payload;
    },
    currentStatesChange(state: any, action: any) {
      state.CurrentStates = action.payload;
    },
    PermanentCitiesChange(state: any, action: any) {
      state.PermanentCities = action.payload;
    },
    PermanentStatesChange(state: any, action: any) {
      state.PermanentStates = action.payload;
    },
  },

  extraReducers: {
    // GET
    [`${getEmployee.pending}`]: GET_EMPLOYEE.pending,
    [`${getEmployee.fulfilled}`]: GET_EMPLOYEE.fulfilled,
    [`${getEmployee.rejected}`]: GET_EMPLOYEE.rejected,

    // GET BY ID
    [`${getEmployeeById.pending}`]: GET_EMPLOYEE_BY_ID.pending,
    [`${getEmployeeById.fulfilled}`]: GET_EMPLOYEE_BY_ID.fulfilled,
    [`${getEmployeeById.rejected}`]: GET_EMPLOYEE_BY_ID.rejected,

    // clear state EMPLOYEE BY ID
    [`${clearEmployeeByIdState.pending}`]: GET_EMPLOYEE_BY_ID.pending,
    [`${clearEmployeeByIdState.fulfilled}`]: GET_EMPLOYEE_BY_ID.fulfilled,
    [`${clearEmployeeByIdState.rejected}`]: GET_EMPLOYEE_BY_ID.rejected,

    // GET CURRENT EMPLOYEE BY ID
    [`${getCurrentEmployeeById.pending}`]: GET_CURRENT_EMPLOYEE_BY_ID.pending,
    [`${getCurrentEmployeeById.fulfilled}`]: GET_CURRENT_EMPLOYEE_BY_ID.fulfilled,
    [`${getCurrentEmployeeById.rejected}`]: GET_CURRENT_EMPLOYEE_BY_ID.rejected,

    // CREATE EMPLOYEE
    [`${saveCreateEmployeeDetails.pending}`]: SAVE_EMPLOYEE_DETAILS.pending,
    [`${saveCreateEmployeeDetails.fulfilled}`]: SAVE_EMPLOYEE_DETAILS.fulfilled,
    [`${saveCreateEmployeeDetails.rejected}`]: SAVE_EMPLOYEE_DETAILS.rejected,

    //  EMPLOYEE PAYLOAD
    [`${saveEmployeePayload.pending}`]: EMPLOYEE_PAYLOAD.pending,
    [`${saveEmployeePayload.fulfilled}`]: EMPLOYEE_PAYLOAD.fulfilled,
    [`${saveEmployeePayload.rejected}`]: EMPLOYEE_PAYLOAD.rejected,

    //  UPDATED PAYLOAD
    [`${putEmployee.pending}`]: UPDATED_EMPLOYEE.pending,
    [`${putEmployee.fulfilled}`]: UPDATED_EMPLOYEE.fulfilled,
    [`${putEmployee.rejected}`]: UPDATED_EMPLOYEE.rejected,
  },
});

export const {
  employeeIdChange,
  isSameAddress,
  teamLeadChange,
  currentCitiesChange,
  currentStatesChange,
  PermanentCitiesChange,
  PermanentStatesChange,
}: any = employeeSlice.actions;
export default employeeSlice.reducer;
