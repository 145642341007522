import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getJobType } from '../thunk/get/getJobType.thunk';
import { GET_JOB_TYPE } from './get/getJob.get';

export const JobTypeSlice = createSlice({
  name: 'JobTypeSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET job Type
    [`${getJobType.pending}`]: GET_JOB_TYPE.pending,
    [`${getJobType.fulfilled}`]: GET_JOB_TYPE.fulfilled,
    [`${getJobType.rejected}`]: GET_JOB_TYPE.rejected,
  },
});

export default JobTypeSlice.reducer;
