/* eslint-disable class-methods-use-this */
import { employmentStatusMasterEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class EmploymentStatusService {
  get(queryParams: any) {
    return commonAPI.get(`/${employmentStatusMasterEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${employmentStatusMasterEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${employmentStatusMasterEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${employmentStatusMasterEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${employmentStatusMasterEndPoint}/${id}`);
  }
}

export default new EmploymentStatusService();
