import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getDesignation } from '../thunk/get/getDesignation.thunk';
import { getDesignationById } from '../thunk/get/getDesignationById.thunk';
import { GET_DESIGNATION } from './get/getDesignation.get';
import { GET_DESIGNATION_BY_ID } from './get/getDesignationById.get';

export const DesignationSlice = createSlice({
  name: 'DesignationSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Blog Category
    [`${getDesignation.pending}`]: GET_DESIGNATION.pending,
    [`${getDesignation.fulfilled}`]: GET_DESIGNATION.fulfilled,
    [`${getDesignation.rejected}`]: GET_DESIGNATION.rejected,
    // GET Blog Category BY ID
    [`${getDesignationById.pending}`]: GET_DESIGNATION_BY_ID.pending,
    [`${getDesignationById.fulfilled}`]: GET_DESIGNATION_BY_ID.fulfilled,
    [`${getDesignationById.rejected}`]: GET_DESIGNATION_BY_ID.rejected,
  },
});

export default DesignationSlice.reducer;
