import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { EMPLOYEE_CODE_BASE, ORGANIZATION_START_DATE } from './const';
import immenceIcon from '../assets/favicon.png';
import String from './string';

export const getNewEmpCode = (lastInsertEmpCode: any) => {
  const lastCodeNumber = Number(lastInsertEmpCode?.split('IMC')?.[1]);
  const newCodeNumber = lastCodeNumber + 1;
  const newCodeNumberLength = newCodeNumber?.toString().length;
  let newEmpCode;
  if (newCodeNumberLength < 5) {
    newEmpCode = EMPLOYEE_CODE_BASE + `${newCodeNumber}`.padStart(5, '0');
  } else {
    newEmpCode = EMPLOYEE_CODE_BASE + newCodeNumber;
  }
  return newEmpCode;
};

export const setToken = (val: any) => localStorage.setItem('accessToken', val);

// eslint-disable-next-line no-unneeded-ternary
export const checkIsAdmin = () => (localStorage.getItem('isAdminView') === 'true' ? true : false);

export const loginEmpId = () => {
  const token: any = localStorage.getItem('accessToken');
  const emp: any = jwtDecode(token);
  return emp.id;
};

export const loginCurEmployee = (empId: any) => {
  const token: any = localStorage.getItem('accessToken');
  const emp: any = jwtDecode(token);
  return emp.id === empId;
};

const buildFormData = (formData: any, data: any, parentKey?: any) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? null : data;
    if (parentKey === 'attachment[0]') {
      formData.append('attachment', value);
    } else {
      formData.append(parentKey, value);
    }
  }
};

export const getFormData = (data: any) => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};

export const defaultGetEmployeeData = {
  page: 1,
  limit: 10,
  searchText: '',
  filterBasedOn: '',
  employmentStatus: '',
  filters: '',
  status: '',
};

export const getQueryParams = (
  filterBasedOn?: any,
  filters?: any,
  page?: any,
  limit?: any,
  searchText?: any,
  employmentStatus?: any,
  status?: any
) => {
  let filterKeys: any = Object.keys(filters).filter((e: any) => filters[e] !== '');
  const condition = (item: string) => !item.includes('Name') && item !== 'All';
  filterKeys = filterKeys.filter(condition);

  let queryParams = '';
  // eslint-disable-next-line array-callback-return
  filterKeys?.map((key: any, index: number) => {
    const findKeyInfo = filterBasedOn?.find((e: any) => e?.key === key);
    let data: any = {};
    data = {
      type: findKeyInfo.queryType,
      value: filters[`${key}`] === 'All' ? 'All' : filters[`${key}`],
    };
    queryParams += `${queryParams ? '&' : '?'}${key}=${data.value}${
      index !== filterKeys.length - 1 ? '' : ''
    }`;
  });
  if (status !== '' && status >= 0) {
    queryParams += `${queryParams ? '&' : '?'}status=${status === 0 ? 'All' : status}`;
  }
  if (employmentStatus !== '' && employmentStatus >= 0) {
    queryParams += `${queryParams ? '&' : '?'}employmentStatus=${
      employmentStatus === 0 ? 'All' : employmentStatus
    }`;
  }
  if (searchText) {
    queryParams += `${queryParams ? '&' : '?'}searchText=${searchText}`;
  }
  if (page) {
    queryParams += `${queryParams ? '&' : '?'}page=${page}`;
  }
  if (limit) {
    queryParams += `${queryParams ? '&' : '?'}limit=${limit}`;
  }
  return queryParams;
};

export const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const openImgInNewTab = (file?: any, title?: any, icon?: any, width?: any, height?: any) => {
  const newTab = window.open();
  newTab?.document.write(
    `<!DOCTYPE html>
      <head>
        <title>${title || String.image_preview}</title>
        <link rel="icon" href=${icon || immenceIcon} />
      </head>
      <body>
        <img src="${file}" width=${width || '40%'} height=${height || '40%'} >
      </body>
    </html>`
  );
  newTab?.document.close();
};

export const getMonths = () => {
  const months = [
    'All',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months.map((month, index) => ({
    id: `${index === 0 ? 'All' : index.toString().padStart(2, '0')}`,
    month,
    monthShortForm: index === 0 ? 'All' : month.substring(0, 3),
  }));
};

export const getYearListFromOrganizationStartDate = () => {
  const fromDate = moment(ORGANIZATION_START_DATE, 'MM/DD/YYYY').year();
  const toDate = moment(new Date(), 'MM/DD/YYYY').year();
  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let i = toDate; i >= fromDate; i--) {
    years.push(i);
  }
  return years;
};

export const getCurrentAndLastDates = () => {
  const currentMonthStart = moment().startOf('month').format('MM/DD/YYYY');
  const currentMonthEnd = moment().endOf('month').format('MM/DD/YYYY');
  const lastMonthStart = moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
  const lastMonthEnd = moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY');

  const obj = {
    currentMonth: {
      startDate: currentMonthStart,
      endDate: currentMonthEnd,
    },
    lastMonth: {
      startDate: lastMonthStart,
      endDate: lastMonthEnd,
    },
  };

  return obj;
};

export function getLast30Days() {
  const currentDate = new Date();

  const endDate = currentDate.toISOString().split('T')[0];

  const startDate = new Date();
  startDate.setDate(currentDate.getDate() - 29);

  const startDateFormatted = startDate.toISOString().split('T')[0];

  return { startDate: startDateFormatted, endDate };
}

export function getPreviousMonths() {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentDate = moment();
  const currentMonthIndex = currentDate.month();

  const currentMonth = {
    month: months[currentMonthIndex],
    startDate: currentDate.startOf('month').format('YYYY-MM-DD'),
    endDate: currentDate.endOf('month').format('YYYY-MM-DD'),
  };

  const previousMonth1 = {
    month: months[(currentMonthIndex + 11) % 12],
    startDate: currentDate.subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    endDate: currentDate.endOf('month').format('YYYY-MM-DD'),
  };

  const previousMonth2 = {
    month: months[(currentMonthIndex + 10) % 12],
    startDate: currentDate.subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    endDate: currentDate.endOf('month').format('YYYY-MM-DD'),
  };

  return [currentMonth, previousMonth1, previousMonth2];
}

export function attendancePercentage(
  attendedHours: any,
  attendedMinutes: any,
  totalHours: any,
  totalMinutes: any
) {
  const totalMinutesInDay = totalHours * 60 + totalMinutes;
  const attendedMinutesInDay = attendedHours * 60 + attendedMinutes || 1;

  const percentage = (attendedMinutesInDay / totalMinutesInDay) * 100;
  return Number(percentage.toFixed(2));
}

export const isPermissionAllowed = (
  admin: boolean,
  data: any,
  modulePath: string,
  action: string
): any => {
  // Find the module with the longest path that matches the start of the modulePath
  const module = data?.reduce((longestMatch: any, item: any) => {
    if (modulePath.startsWith(item.path) && item.path.length > (longestMatch?.path.length || 0)) {
      return item;
    }
    return longestMatch;
  }, null);

  // If module exists and admin is true, check the module's permissions
  if (module) {
    return (admin && module.onlyAdmin && module[action]) || (!module.onlyAdmin && module[action]);
  }

  return false;
};

export const isPermissionAllowedDashBoard = (
  data: any,
  modulePath: string,
  action: string
): any => {
  const module = data?.find((item: any) => modulePath.startsWith(item.path)) || null;

  if (module) {
    return module[action];
  }

  return false;
};

export const totalNotificationCount = (data: any, type: string) => {
  if (type === 'all') {
    return data?.length;
  }
  if (type === 'read') {
    const readNotification = data.filter((e: any) => e.isRead === 1);
    return readNotification?.length;
  }
  if (type === 'unread') {
    const readNotification = data.filter((e: any) => e.isRead !== 1);
    return readNotification?.length;
  }
  return '';
};

export const filterNotificationData = (data: any, type: any) => {
  if (type === 'all') {
    return data;
  }
  return data.filter((notification: any) => {
    if (type === 0) {
      return notification.isRead === 0;
    }
    if (type === 1) {
      return notification.isRead === 1;
    }

    return true;
  });
};

export function convertToYearMonth(dateString: any) {
  return moment(dateString, 'MMMM YYYY').format('YYYY-MM');
}

export const formatNumberToTwoDecimalPlaces = (number: number | string) => {
  if (!number) return 0;
  const formattedNumber = Number(number).toFixed(2);
  return formattedNumber;
};

export const makeFalsy = (value: any) => {
  if (
    value === null ||
    value === undefined ||
    value === false ||
    value === 0 ||
    value === 'null' ||
    value === 'undefined' ||
    value === 'false' ||
    value === '0'
  ) {
    return false;
  }
  return value;
};

export function timeStringToDate(timeString: string): Date | string {
  // eslint-disable-next-line eqeqeq
  if (timeString == '') {
    return '';
  }
  const timeParts = timeString.split(':').map(Number);

  if (
    timeParts.length !== 3 ||
    // eslint-disable-next-line no-restricted-globals
    timeParts.some(isNaN) ||
    timeParts[0] < 0 ||
    timeParts[0] > 23 ||
    timeParts[1] < 0 ||
    timeParts[1] > 59 ||
    timeParts[2] < 0 ||
    timeParts[2] > 59
  ) {
    throw new Error('Invalid time string format. Expected "HH:MM:SS" with valid time values.');
  }

  const [hours, minutes, seconds] = timeParts;

  const date = new Date();
  date.setHours(hours, minutes, seconds, 0);

  return date;
}
