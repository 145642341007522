/* eslint-disable class-methods-use-this */
import { bankEndPoint } from 'src/api/api-end-points';
import commonAPI from 'src/api/common';

class BankService {
  get() {
    return commonAPI.get(`/${bankEndPoint}`);
  }
}

export default new BankService();
