import { InitialState } from '../type';

export const DELETE_HOLIDAY = {
  pending: (state: InitialState, action: any) => {
    state.delete.isLoading = true;
    state.delete.isSuccess = false;
    state.delete.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.delete.errorMessage = action.payload.code;
    state.delete.isLoading = false;
    state.delete.isSuccess = true;
    state.delete.isError = false;
  },
  rejected: (state: InitialState, action: any) => {
    state.delete.isLoading = false;
    state.delete.isSuccess = false;
    state.delete.isError = true;
    state.delete.errorMessage = action?.payload?.data?.message;
  },
};
