import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getHolidayType } from '../thunk/get/getHolidayType.thunk';
import { getHolidayTypeById } from '../thunk/get/getHolidayTypeById.thunk';
import { GET_HOLIDAY_TYPE } from './get/getHolidayType.get';
import { GET_HOLIDAY_TYPE_BY_ID } from './get/getHolidayTypeById.get';

export const HolidayTypeSlice = createSlice({
  name: 'HolidayTypeSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Holiday Type
    [`${getHolidayType.pending}`]: GET_HOLIDAY_TYPE.pending,
    [`${getHolidayType.fulfilled}`]: GET_HOLIDAY_TYPE.fulfilled,
    [`${getHolidayType.rejected}`]: GET_HOLIDAY_TYPE.rejected,
    // GET Holiday Type BY ID
    [`${getHolidayTypeById.pending}`]: GET_HOLIDAY_TYPE_BY_ID.pending,
    [`${getHolidayTypeById.fulfilled}`]: GET_HOLIDAY_TYPE_BY_ID.fulfilled,
    [`${getHolidayTypeById.rejected}`]: GET_HOLIDAY_TYPE_BY_ID.rejected,
  },
});

export default HolidayTypeSlice.reducer;
