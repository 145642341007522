import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginPath } from 'src/utils/const';
import { logoutUser } from 'src/redux/auth/thunk/post/logout.thunk';
import { enqueueSnackbar } from 'notistack';
import holidayServices from '../../services/holiday.services';

export const deleteHoliday = createAsyncThunk(
  'delete-company-holiday',
  async (payload: any, thunkAPI) => {
    try {
      const response = await holidayServices.delete(payload);
      return response;
    } catch (error) {
      if (error.response.data.code === 403) {
        localStorage.setItem('isAdminView', 'false');
        window.location.href = '/home';
        enqueueSnackbar(error.response.data.message || 'Something Went Wrong!', {
          variant: 'warning',
        });
      } else if (error.response.data.code === 401 || error.response.data.code === 400) {
        localStorage.removeItem('accessToken');
        window.location.href = loginPath;
        enqueueSnackbar(error.response.data.message || 'Please Login!', {
          variant: 'error',
        });
        thunkAPI.dispatch(logoutUser());
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
