import { changePasswordEndPoint } from 'src/api/api-end-points';
import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../services/auth.service';

export const changePassword = createAsyncThunk(
  'auth-change-password',
  async (payload: any, thunkAPI) => {
    try {
      const response = await authService.post(changePasswordEndPoint, payload);
      if (response.data.code === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
