import { InitialState } from '../type';

export const GET_LEAVE_BY_EMP_ID = {
  pending: (state: InitialState, action: any) => {
    state.getLeaveByEmpId.isLoading = true;
    state.getLeaveByEmpId.isSuccess = false;
    state.getLeaveByEmpId.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.getLeaveByEmpId.empLeaveData = action.payload.data.data?.employeeLeave;
    state.getLeaveByEmpId.isLoading = false;
    state.getLeaveByEmpId.isSuccess = true;
    state.getLeaveByEmpId.isError = false;
  },
  rejected: (state: InitialState, action: any) => {
    state.getLeaveByEmpId.isLoading = false;
    state.getLeaveByEmpId.isSuccess = false;
    state.getLeaveByEmpId.isError = true;
    state.getLeaveByEmpId.errorMessage = action?.payload?.data?.message;
  },
};
