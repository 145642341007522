export const initialState = {
  get: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    employeeById: [],
    employeeData: [],
    currentEmployee: [],
    totalItems: '',
  },
  post: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    createEmployeeDetails: {},
    employeePayload: [],
  },
  put: {
    updatedEmployee: [],
  },
  delete: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  notSame: false,
  CurrentCities: [],
  CurrentStates: [],
  PermanentCities: [],
  PermanentStates: [],
  TeamLeadState: [],
  empId: null,
  changeFlag: false,
};
