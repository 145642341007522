import merge from 'lodash/merge';
import { Theme } from '@mui/material/styles';
//
import Fab from './components/Fab';
import Card from './components/Card';
import Chip from './components/Chip';
import Tabs from './components/Tabs';
import Menu from './components/Menu';
import Link from './components/Link';
import Lists from './components/List';
import Table from './components/Table';
import Alert from './components/Alert';
import Badge from './components/Badge';
import Paper from './components/Paper';
import Stack from './components/Stack';
import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import Dialog from './components/Dialog';
import Avatar from './components/Avatar';
import Rating from './components/Rating';
import Slider from './components/Slider';
import Button from './components/button';
import Switch from './components/Switch';
import Select from './components/Select';
import Tooltip from './components/Tooltip';
import Popover from './components/Popover';
import Stepper from './components/Stepper';
import SvgIcon from './components/SvgIcon';
import Skeleton from './components/Skeleton';
import Backdrop from './components/Backdrop';
import Progress from './components/Progress';
import Timeline from './components/Timeline';
import Checkbox from './components/Checkbox';
import DataGrid from './components/DataGrid';
import TreeView from './components/TreeView';
import TextField from './components/Textfield';
import Accordion from './components/Accordion';
import Typography from './components/Typography';
import Pagination from './components/Pagination';
import Breadcrumbs from './components/Breadcrumbs';
import CssBaseline from './components/CssBaseline';
import RadioButton from './components/RadioButton';
import ButtonGroup from './components/ButtonGroup';
import Autocomplete from './components/Autocomplete';
import MuiDatePicker from './components/DatePicker';
import ToggleButton from './components/ToggleButton';
import LoadingButton from './components/LoadingButton';

// ----------------------------------------------------------------------

export function componentsOverrides(theme: Theme) {
  const components = merge(
    Fab(theme),
    Tabs(theme),
    Chip(theme),
    Card(theme),
    Menu(theme),
    Link(theme),
    Stack(theme),
    Badge(theme),
    Lists(theme),
    Table(theme),
    Paper(theme),
    Alert(theme),
    Switch(theme),
    Select(theme),
    Button(theme),
    Rating(theme),
    Dialog(theme),
    AppBar(theme),
    Avatar(theme),
    Slider(theme),
    Drawer(theme),
    Stepper(theme),
    Tooltip(theme),
    Popover(theme),
    SvgIcon(theme),
    Checkbox(theme),
    DataGrid(theme),
    Skeleton(theme),
    Timeline(theme),
    TreeView(theme),
    Backdrop(theme),
    Progress(theme),
    TextField(theme),
    Accordion(theme),
    Typography(theme),
    Pagination(theme),
    RadioButton(theme),
    ButtonGroup(theme),
    Breadcrumbs(theme),
    CssBaseline(theme),
    Autocomplete(theme),
    ToggleButton(theme),
    MuiDatePicker(theme),
    LoadingButton(theme)
  );

  return components;
}
