import { m } from 'framer-motion';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useRouter } from 'src/routes/hook';
import { useMockedUser } from 'src/hooks/useMockedUser';
import { useAuthContext } from 'src/auth/hooks';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { permissionMasterEndPoint } from 'src/api/api-end-points';
import { getMaster } from 'src/redux/master/thunk/master.thunk';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { getCurrentEmployeeById } from 'src/redux/employee/thunk/get/getCurEmpById.thunk';
import { getEmployeeById } from 'src/redux/employee/thunk/get/getEmpById.thunk';
import { CustomAvatar } from 'src/components/custom-avatar';
import { API_URL, API_VERSION } from 'src/utils/const';
import { paths } from 'src/routes/paths';
import { loginCurEmployee, loginEmpId } from 'src/utils/helper';
import { useNavigate } from 'react-router';
import { getBioDetails } from 'src/redux/bio/thunk/get/getBioDetails.thunk.';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { user } = useMockedUser();
  const { logout } = useAuthContext();
  const popover = usePopover();
  const { isAdmin, permissionData } = useSelector((state: any) => state.permissions?.get);
  const { currentEmployee } = useSelector((state: any) => state.employee.get);
  const hasThemePermissions = permissionData?.[0]?.permissionData?.some(
    (perm: any) => perm.add || perm.edit || perm.delete
  );

  const empId = loginEmpId();
  const navigate = useNavigate();

  const loginCurEmployeeId = loginCurEmployee(currentEmployee?.id);

  useEffect(() => {
    dispatch(getCurrentEmployeeById(empId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   dispatch(getMaster(permissionMasterEndPoint));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const OPTIONS = [
    {
      label: 'Profile',
      linkTo: `${paths.PATH_DASHBOARD.employee.details(empId, 'general')}`,
    },
    {
      label: 'Attendance',
      linkTo: `/`,
    },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path: string) => {
    dispatch(getEmployeeById(empId));
    dispatch(getBioDetails(empId));
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <CustomAvatar
          // eslint-disable-next-line no-nested-ternary
          src={
            // eslint-disable-next-line no-nested-ternary
            currentEmployee?.avatar ? `${API_URL}${API_VERSION}/${currentEmployee?.avatar}` : ``
          }
          alt={`${currentEmployee?.firstName} ${currentEmployee?.lastName}`}
          name={`${currentEmployee?.firstName} ${currentEmployee?.lastName}`}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
