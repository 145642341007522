/* eslint-disable class-methods-use-this */
import { requestTypeMasterEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class RequestTypeService {
  get(queryParams: any) {
    return commonAPI.get(`/${requestTypeMasterEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${requestTypeMasterEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${requestTypeMasterEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${requestTypeMasterEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${requestTypeMasterEndPoint}/${id}`);
  }
}

export default new RequestTypeService();
