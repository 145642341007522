import { InitialState } from '../type';

export const FORGOT_PASSWORD = {
  pending: (state: InitialState, action: any) => {
    state.forgotPasswordState.isLoading = true;
    state.forgotPasswordState.isSuccess = false;
    state.forgotPasswordState.isError = false;
    state.forgotPasswordState.errorMessage = '';
    state.forgotPasswordState.message = '';
  },
  fulfilled: (state: InitialState, action: any) => {
    state.forgotPasswordState.message = action.payload.message;
    state.forgotPasswordState.isLoading = false;
    state.forgotPasswordState.isSuccess = true;
    state.forgotPasswordState.isError = false;
    state.forgotPasswordState.errorMessage = '';
  },
  rejected: (state: InitialState, action: any) => {
    state.forgotPasswordState.message = '';
    state.forgotPasswordState.errorMessage = action.payload.response.data.message;
    state.forgotPasswordState.isLoading = false;
    state.forgotPasswordState.isSuccess = false;
    state.forgotPasswordState.isError = true;
  },
};
