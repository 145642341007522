import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getExercise } from '../thunk/get/getExercise';
import { getExerciseById } from '../thunk/get/getExerciseById.thunk';
import { GET_EXERCISE } from './getSlices/getExercise.get';
import { GET_EXERCISE_BY_ID } from './getSlices/getExerciseById.get';

export const ExerciseSlice = createSlice({
  name: 'ExerciseSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET ASSETS
    [`${getExercise.pending}`]: GET_EXERCISE.pending,
    [`${getExercise.fulfilled}`]: GET_EXERCISE.fulfilled,
    [`${getExercise.rejected}`]: GET_EXERCISE.rejected,

    // GET ASSET BY ID
    [`${getExerciseById.pending}`]: GET_EXERCISE_BY_ID.pending,
    [`${getExerciseById.fulfilled}`]: GET_EXERCISE_BY_ID.fulfilled,
    [`${getExerciseById.rejected}`]: GET_EXERCISE_BY_ID.rejected,
  },
});

export default ExerciseSlice.reducer;
