import { forwardRef } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import Iconify from 'src/components/iconify';
import { Box, Stack, alpha, useTheme } from '@mui/material';
import { CustomAvatar } from 'src/components/custom-avatar';
import { API_URL, API_VERSION } from 'src/utils/const';
import SvgColor from 'src/components/svg-color';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

export default function PraisePopup({
  openPraiseDialog,
  onOpenPraiseDialog,
  onClosePraiseDialog,
}: any) {
  const theme = useTheme();
  const praisePopupDataByIndex = useSelector(
    (state: any) => state.notification?.praisePopupDataByIndex
  );
  return (
    <Dialog
      fullScreen
      open={openPraiseDialog}
      onClose={onClosePraiseDialog}
      sx={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
      }}
      TransitionComponent={Transition}
    >
      <Toolbar
        sx={{
          width: '100%',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <IconButton
          edge="end"
          sx={{
            flex: 'end',
            color: theme.palette.grey[500],
            flexShrink: 0,
            width: '50px',
            height: '50px',
          }}
          color="inherit"
          onClick={onClosePraiseDialog}
        >
          <Iconify icon="mingcute:close-fill" />
        </IconButton>
      </Toolbar>

      <Stack
        sx={{
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack sx={{ position: 'relative', marginBottom: '20px' }}>
          <div
            style={{
              border: `1px solid ${theme.palette.grey[100]}`,
              borderRadius: '50%',
            }}
          >
            <CustomAvatar
              sx={{
                border: '5px solid white',
                // margin: '10px',
                width: '140px',
                height: '140px',
              }}
              // eslint-disable-next-line no-nested-ternary
              src={
                // eslint-disable-next-line no-nested-ternary
                praisePopupDataByIndex?.toEmployeeAvatar
                  ? `${API_URL}${API_VERSION}/${praisePopupDataByIndex?.toEmployeeAvatar}`
                  : ``
              }
              alt={`${praisePopupDataByIndex?.toEmployeeName}`}
              name={`${praisePopupDataByIndex?.toEmployeeName}`}
            />
          </div>
          <img
            src="/assets/icons/home/clap.png"
            style={{
              position: 'absolute',
              bottom: 1,
              right: 1,
              width: '60px',
              height: '60px',
            }}
            alt=""
          />
        </Stack>
        <Typography
          sx={{
            textAlign: 'center',
            color: theme.palette.secondary.dark,
            fontSize: '35px',
            fontWeight: 700,
            mt: 1,
          }}
        >
          Wohoo! You’re Praised!
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.primary.main,
            fontSize: '21px',
            fontWeight: 400,
            textTransform: 'capitalize',
            mt: 1,
          }}
        >
          By{' '}
          <span style={{ margin: '2px 10px 0 10px' }}>
            {' '}
            <CustomAvatar
              sx={{
                width: '20px',
                height: '20px',
              }}
              // eslint-disable-next-line no-nested-ternary
              src={
                // eslint-disable-next-line no-nested-ternary
                praisePopupDataByIndex?.byEmployeeAvatar
                  ? `${API_URL}${API_VERSION}/${praisePopupDataByIndex?.byEmployeeAvatar}`
                  : ``
              }
              alt={`${praisePopupDataByIndex?.byEmployeeName}`}
              name={`${praisePopupDataByIndex?.byEmployeeName}`}
            />
          </span>{' '}
          {praisePopupDataByIndex?.byEmployeeName || ''}
        </Typography>

        <Typography
          sx={{
            color: theme.palette.grey[600],
            fontSize: '21px',
            fontWeight: 400,
            textAlign: 'center',
            mt: 3,
            maxWidth: '700px',
            px: 2,
          }}
        >
          <SvgColor src="/assets/icons/home/ic_quotationStart.svg" />
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {praisePopupDataByIndex.description}
          </span>
          <SvgColor src="/assets/icons/home/ic_quotationEnd.svg" />
        </Typography>
      </Stack>
    </Dialog>
  );
}
