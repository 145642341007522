import { InitialState } from '../type';

export const GET_REQUEST_TYPE_BY_ID = {
  pending: (state: InitialState, action: any) => {
    state.get.isLoading = true;
    state.get.isSuccess = false;
    state.get.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.get.requestTypeById = action.payload?.data?.data?.requestType;
    state.get.isLoading = false;
    state.get.isSuccess = false;
    state.get.isError = false;
  },

  rejected: (state: InitialState, action: any) => {
    state.get.isLoading = false;
    state.get.isSuccess = false;
    state.get.isError = false;
  },
};
