/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { notificationEndPoint } from 'src/api/api-end-points';
import commonAPI from '../../../api/common';

class NotificationService {
  get(queryParams: string) {
    return commonAPI.get(`/${notificationEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${notificationEndPoint}/${id}`);
  }

  put(id: number, data: any) {
    return commonAPI.put(`/${notificationEndPoint}/${id}`, data);
  }
}

export default new NotificationService();
