import { InitialState } from '../type';

export const GET_SUBTOPIC = {
  pending: (state: InitialState, action: any) => {
    state.get.isLoading = true;
    state.get.isSuccess = false;
    state.get.isError = false;
    state.get.errorMessage = '';
  },
  fulfilled: (state: InitialState, action: any) => {
    state.get.subtopicList = action.payload?.data?.data?.subTopic;
    state.get.isLoading = false;
    state.get.isSuccess = false;
    state.get.isError = false;
  },

  rejected: (state: InitialState, action: any) => {
    state.get.isLoading = false;
    state.get.isSuccess = false;
    state.get.isError = false;
    state.get.errorMessage = action?.payload?.data?.message;
  },
};
