import { createSlice } from '@reduxjs/toolkit';
import { getBankDetails } from '../thunk/getBankDetails.thunk';
import { initialState } from './initialState';
import { GET_BANK_DETAILS } from './getSlices/getBankDetails.get';

const bankSlice: any = createSlice({
  name: 'bankSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Bank
    [`${getBankDetails.pending}`]: GET_BANK_DETAILS.pending,
    [`${getBankDetails.fulfilled}`]: GET_BANK_DETAILS.fulfilled,
    [`${getBankDetails.rejected}`]: GET_BANK_DETAILS.rejected,
  },
});

export default bankSlice.reducer;
