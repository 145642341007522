import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginPath } from 'src/utils/const';
import { logoutUser } from 'src/redux/auth/thunk/post/logout.thunk';
import { enqueueSnackbar } from 'notistack';
import attendanceServices from '../../services/attendance.service';

export const getAttendance = createAsyncThunk('get-attendance', async (payload, thunkAPI) => {
  try {
    const response = await attendanceServices.get();
    return response;
  } catch (error) {
    if (error.response.data.code === 403) {
      localStorage.setItem('isAdminView', 'false');
      window.location.href = '/home';
      enqueueSnackbar(error.response.data.message || 'Something Went Wrong!', {
        variant: 'warning',
      });
    } else if (error.response.data.code === 401 || error.response.data.code === 400) {
      localStorage.removeItem('accessToken');
      window.location.href = loginPath;
      enqueueSnackbar(error.response.data.message || 'Please Login!', {
        variant: 'error',
      });
      thunkAPI.dispatch(logoutUser());
    }
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAttendanceList = createAsyncThunk(
  'get-attendance-list',
  async (payload: any, thunkAPI) => {
    try {
      const response = await attendanceServices.getAttendanceList(payload);
      return response;
    } catch (error) {
      if (error.response.data.code === 403) {
        localStorage.setItem('isAdminView', 'false');
        window.location.href = '/home';
        enqueueSnackbar(error.response.data.message || 'Something Went Wrong!', {
          variant: 'warning',
        });
      } else if (error.response.data.code === 401 || error.response.data.code === 400) {
        localStorage.removeItem('accessToken');
        window.location.href = loginPath;
        enqueueSnackbar(error.response.data.message || 'Please Login!', {
          variant: 'error',
        });
        thunkAPI.dispatch(logoutUser());
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
