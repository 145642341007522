import { InitialState } from '../type';

export const CHANGE_PASSWORD = {
  pending: (state: InitialState, action: any) => {
    state.changePasswordState.isLoading = true;
    state.changePasswordState.isSuccess = false;
    state.changePasswordState.isError = false;
    state.changePasswordState.errorMessage = '';
    state.changePasswordState.message = '';
  },
  fulfilled: (state: InitialState, action: any) => {
    state.changePasswordState.message = action.payload.message;
    state.changePasswordState.isLoading = false;
    state.changePasswordState.isSuccess = true;
    state.changePasswordState.isError = false;
    state.changePasswordState.errorMessage = '';
  },
  rejected: (state: InitialState, action: any) => {
    state.changePasswordState.message = '';
    state.changePasswordState.errorMessage = action.payload.response.data.message;
    state.changePasswordState.isLoading = false;
    state.changePasswordState.isSuccess = false;
    state.changePasswordState.isError = true;
  },
};
