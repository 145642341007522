/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import {
  courseAdminEndPoint,
  courseAssignEndPoint,
  courseEndPoint,
  courseSeqNumber,
} from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class CourseService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${courseAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${courseEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${courseEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${courseEndPoint}`, data);
  }

  courseAssign(data: any) {
    return commonAPI.post(`/${courseAssignEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${courseEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${courseEndPoint}/${id}`);
  }

  updateSeqNumber(data: any) {
    return commonAPI.put(`/${courseSeqNumber}`, data);
  }
}

export default new CourseService();
