import { useMemo } from 'react';
import SvgColor from 'src/components/svg-color';
import { useSelector } from 'src/redux/store';
import { API_URL, API_VERSION } from 'src/utils/const';
import { Module, TransformedData, TransformedModule } from 'src/@types/navConfig';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  home: icon('ic_home'),
  employee: icon('ic_employee'),
  assets: icon('ic_assets'),
  permissions: icon('ic_permission'),
  drive: icon('ic_drive'),
  document: icon('ic_document'),
  policy: icon('ic_policy'),
  activities: icon('ic_activitie'),
  grantLeave: icon('ic_grantLeave'),
  leave: icon('ic_leave'),
  holiday: icon('ic_holiday'),
  training: icon('ic_training'),
  attendance: icon('ic_attendance'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { permissionData, isAdmin } = useSelector((state: any) => state.permissions?.get);
  const adminView = useSelector((state: any) => state.auth.adminView);

  const adminTheme = isAdmin && adminView;

  const transformData = (data: { permission: Module[] }): TransformedData[] => {
    const modulesByCategory: Record<string, Module[]> = {};

    data?.permission?.forEach((module) => {
      const category = module.moduleCategoryName || 'Uncategorized';
      modulesByCategory[category] = modulesByCategory[category] || [];
      modulesByCategory[category].push(module);
    });

    const result: TransformedData[] = Object.entries(modulesByCategory).map(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      ([category, modules]) => {
        const items: TransformedModule[] = modules
          .filter((module) => module.view && !module.parentModuleId)
          .sort((a, b) => (a.seqNo || 0) - (b.seqNo || 0))
          .map((module) => {
            const transformedModule: TransformedModule = {
              title: module.moduleName || '',
              path: module.path || null,
              icon:
                (
                  <SvgColor
                    src={`${API_URL}${API_VERSION}/${module.icon}`}
                    sx={{ width: 1, height: 1 }}
                  />
                ) || null,
              onlyAdmin: module.onlyAdmin || false,
              add: module.add,
              edit: module.edit,
              delete: module.delete,
              view: module.view,
            };

            const children = modules
              .filter((childModule) => childModule.parentModuleName === module.moduleName)
              .map((childModule) => ({
                title: childModule.moduleName || '',
                path: childModule.path || null,
                icon:
                  (
                    <SvgColor
                      src={`${API_URL}${API_VERSION}/${childModule.icon}`}
                      sx={{ width: 1, height: 1 }}
                    />
                  ) || null,
                onlyAdmin: childModule.onlyAdmin || false,
              }));

            if (children.length > 0) {
              transformedModule.children = children;
            }

            return transformedModule;
          });

        return {
          subheader: category,
          items,
        };
      }
    );

    const forEmployeeViewResult = result
      ?.map((category: any) => ({
        ...category,
        items: category.items
          ?.filter((item: any) => !item.onlyAdmin)
          ?.map((rest: any) => ({
            ...rest,
            children: rest?.children?.filter((c: any) => !c.onlyAdmin),
          })),
      }))
      ?.filter((category: any) => category?.items?.length > 0);

    const forAdminThemeResult = result
      ?.map((category: any) => ({
        ...category,
        items: category.items?.filter(
          (item: any) => item.view && (item.add || item.edit || item.delete)
        ),
      }))
      ?.filter((category: any) => category?.items?.length > 0);

    const final = adminTheme ? forAdminThemeResult : forEmployeeViewResult;

    return final;
  };

  const transformedData = useMemo(
    () => transformData({ permission: [...permissionData] }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permissionData, adminTheme]
  );

  const data = useMemo(() => [...transformedData], [transformedData]);

  return data;
}
