/* eslint-disable class-methods-use-this */
import { bioEndPoint } from 'src/api/api-end-points';
import commonAPI from 'src/api/common';

class BioService {
  get(id: any) {
    return commonAPI.get(`/${bioEndPoint}/${id}`);
  }

  put(data: any) {
    return commonAPI.put(`/${bioEndPoint}`, data);
  }
}

export default new BioService();
