import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getPolicy } from '../thunk/get/getPolicy';
import { getPolicyById } from '../thunk/get/getPolicyById.thunk';
import { GET_POLICY } from './getSlices/getPolicy.get';
import { GET_POLICY_BY_ID } from './getSlices/getPolicyById.get';

export const PolicySlice = createSlice({
  name: 'PolicySlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET POLICY
    [`${getPolicy.pending}`]: GET_POLICY.pending,
    [`${getPolicy.fulfilled}`]: GET_POLICY.fulfilled,
    [`${getPolicy.rejected}`]: GET_POLICY.rejected,

    // GET POLICY BY ID
    [`${getPolicyById.pending}`]: GET_POLICY_BY_ID.pending,
    [`${getPolicyById.fulfilled}`]: GET_POLICY_BY_ID.fulfilled,
    [`${getPolicyById.rejected}`]: GET_POLICY_BY_ID.rejected,
  },
});

export default PolicySlice.reducer;
