/* eslint-disable class-methods-use-this */
import { blogCategoryMasterEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class BlogCategoryService {
  get(queryParams: any) {
    return commonAPI.get(`/${blogCategoryMasterEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${blogCategoryMasterEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${blogCategoryMasterEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${blogCategoryMasterEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${blogCategoryMasterEndPoint}/${id}`);
  }
}

export default new BlogCategoryService();
