import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getMaster } from '../thunk/master.thunk';
import { MASTER } from './get-slices/master.get';

export const masterSlice: any = createSlice({
  name: 'masterSlice',
  initialState,
  reducers: {
    createEditMasterDialog(state: any, action: any) {
      state.isOpen = action.payload.isOpen;
      state.isCreate = action.payload.isCreate;
      state.isEdit = action.payload.isEdit;
      state.typeName = action.payload.typeName;
      state.masterName = action.payload.masterName;
    },
  },
  extraReducers: {
    // get master
    [`${getMaster.pending}`]: MASTER.pending,
    [`${getMaster.fulfilled}`]: MASTER.fulfilled,
    [`${getMaster.rejected}`]: MASTER.rejected,
  },
});

export const { createEditMasterDialog } = masterSlice.actions;
export default masterSlice.reducer;
