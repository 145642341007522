/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
// routes
import slugify from 'slugify';
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Math.floor(Date.now() / 1000);

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft: any = exp < currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');
    localStorage.clear();

    window.location.href = paths.auth.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    localStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    // tokenExpired(exp);
  } else {
    sessionStorage.clear();
    localStorage.clear();

    delete axios.defaults.headers.common.Authorization;
  }
};

// ------------------------------------------

export function MakeSlugFromTitle(title: any) {
  const cleanedTitle = title.replace(/[^\w\s]/gi, ' ');
  const singleSpaceTitle = cleanedTitle.replace(/\s+/g, ' ');
  const slug = singleSpaceTitle.trim().toLowerCase().replace(/\s+/g, '-');
  return slug;
}

export function makeSlugFromTitle(title: string | any) {
  const slug = slugify(title, {
    remove: /[*+~.()'"!:@]/g,
    lower: true,
    replacement: '-',
  });
  return slug;
}

export function convertTime(timeStr: string): string {
  if (!timeStr || typeof timeStr !== 'string') return '';

  const [hours = 0, minutes = 0, seconds = 0] = timeStr.split(':').map(Number);

  const totalHours = hours;
  const totalMinutes = minutes + (seconds > 0 ? 1 : 0);

  if (totalHours > 0) {
    return totalMinutes > 0 ? `${totalHours}h ${totalMinutes}m` : `${totalHours}h`;
  }
  return `${totalMinutes}m`;
}
