/* eslint-disable class-methods-use-this */
import { holidayAdminEndPoint, holidayEndPoint } from 'src/api/api-end-points';
import { checkIsAdmin } from 'src/utils/helper';
import commonAPI from '../../../api/common';

class HolidayService {
  get(queryParams: string) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${holidayAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${holidayEndPoint}${queryParams || ''}`);
  }

  getOptionalLeave() {
    return commonAPI.get(`/${holidayEndPoint}?type=17`);
  }

  getById(id: number) {
    return commonAPI.get(`/${holidayEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${holidayEndPoint}`, data);
  }

  put(id: number, data: any) {
    return commonAPI.put(`/${holidayEndPoint}/${id}`, data);
  }

  delete(id: number) {
    return commonAPI.delete(`/${holidayEndPoint}/${id}`);
  }
}

export default new HolidayService();
