export const initialState = {
  get: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    dailyLogs: [],
    upcomingHolidays: [],
    upcomingBirthDays: [],
    upcomingActivities: [],
    speedyImmencer: [],
    dedicatedImmencer: [],
    onLeaveToday: [],
    feed: [],
    totalItems: '',
    optionalHoliday: [],
  },
  post: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  put: { isLoading: false, isSuccess: false, isError: false, errorMessage: '' },
  delete: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
};
