import { forgotPasswordEndPoint } from 'src/api/api-end-points';
import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../services/auth.service';

export const forgotPassword = createAsyncThunk(
  'auth-forgot-password',
  async (payload: any, thunkAPI) => {
    try {
      const response = await authService.post(forgotPasswordEndPoint, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
