import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getSubtopic } from '../thunk/get/getSubtopic';
import { getSubtopicById } from '../thunk/get/getSubtopicById.thunk';
import { GET_SUBTOPIC } from './getSlices/getSubtopic.get';
import { GET_SUBTOPIC_BY_ID } from './getSlices/getSubtopicById.get';

export const SubtopicSlice = createSlice({
  name: 'SubtopicSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET SUBTOPIC
    [`${getSubtopic.pending}`]: GET_SUBTOPIC.pending,
    [`${getSubtopic.fulfilled}`]: GET_SUBTOPIC.fulfilled,
    [`${getSubtopic.rejected}`]: GET_SUBTOPIC.rejected,

    // GET SUBTOPIC BY ID
    [`${getSubtopicById.pending}`]: GET_SUBTOPIC_BY_ID.pending,
    [`${getSubtopicById.fulfilled}`]: GET_SUBTOPIC_BY_ID.fulfilled,
    [`${getSubtopicById.rejected}`]: GET_SUBTOPIC_BY_ID.rejected,
  },
});

export default SubtopicSlice.reducer;
