import { InitialState } from '../type';

export const PUT_BIO = {
  pending: (state: InitialState, action: any) => {
    state.put.isLoading = true;
    state.put.isSuccess = false;
    state.put.isError = false;
    state.put.errorMessage = '';
  },
  fulfilled: (state: InitialState, action: any) => {
    state.put.bio = action?.payload?.data
      ? action?.payload?.data?.data?.bioData[0]
      : action?.payload;
    state.put.isLoading = false;
    state.put.isSuccess = true;
    state.put.isError = false;
    state.put.errorMessage = '';
  },
  rejected: (state: InitialState, action: any) => {
    state.put.isLoading = false;
    state.put.isSuccess = false;
    state.put.isError = true;
    state.put.errorMessage = action.payload?.response?.data?.message;
  },
};
