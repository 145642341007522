export const initialState = {
  get: {
    isLoading: true,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    assetsById: {},
    assetsList: [],
    totalItems: '',
  },
  post: {
    isLoading: true,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  put: { isLoading: true, isSuccess: false, isError: false, errorMessage: '' },
  delete: {
    isLoading: true,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
};
