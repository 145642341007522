// ----------------------------------------------------------------------

const ROOTS = {
  ROOTS_AUTH: '/auth',
  ROOTS_DASHBOARD: '/home',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.ROOTS_AUTH}/login`,
    reset: `${ROOTS.ROOTS_AUTH}/reset`,
    setPassword: (id: string) => `${ROOTS.ROOTS_AUTH}/set-password/${id}`,
    register: `${ROOTS.ROOTS_AUTH}/register`,
  },
  // DASHBOARD
  PATH_DASHBOARD: {
    root: ROOTS.ROOTS_DASHBOARD,
    one: `${ROOTS.ROOTS_DASHBOARD}/one`,
    employee: {
      root: `${ROOTS.ROOTS_DASHBOARD}/employee`,
      new: `${ROOTS.ROOTS_DASHBOARD}/employee/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/employee/${id}/edit`,
      details: (id: any, tab: string) =>
        `${ROOTS.ROOTS_DASHBOARD}/employee/details/${id}?tab=${tab}`,
      view: (view: string) => `${ROOTS.ROOTS_DASHBOARD}/employee?view=${view}`,
    },
    permissions: {
      root: `${ROOTS.ROOTS_DASHBOARD}/permissions`,
    },
    assets: {
      root: `${ROOTS.ROOTS_DASHBOARD}/assets`,
      new: `${ROOTS.ROOTS_DASHBOARD}/assets/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/assets/${id}/edit`,
      details: (id: any) => `${ROOTS.ROOTS_DASHBOARD}/assets/details/${id}`,
    },
    drive: {
      root: `${ROOTS.ROOTS_DASHBOARD}/drive`,
      new: `${ROOTS.ROOTS_DASHBOARD}/drive/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/drive/${id}/edit`,
      details: (id: any) => `${ROOTS.ROOTS_DASHBOARD}/drive/details/${id}`,
    },
    document: {
      root: `${ROOTS.ROOTS_DASHBOARD}/document`,
      new: `${ROOTS.ROOTS_DASHBOARD}/document/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/document/${id}/edit`,
      details: (id: any) => `${ROOTS.ROOTS_DASHBOARD}/document/details/${id}`,
    },
    policy: {
      root: `${ROOTS.ROOTS_DASHBOARD}/policy`,
      new: `${ROOTS.ROOTS_DASHBOARD}/policy/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/policy/${id}/edit`,
      details: (id: any) => `${ROOTS.ROOTS_DASHBOARD}/policy/details/${id}`,
    },
    course: {
      root: `${ROOTS.ROOTS_DASHBOARD}/training/courses`,
      new: `${ROOTS.ROOTS_DASHBOARD}/training/courses/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/training/courses/${id}/edit`,
      details: (id: any) => `${ROOTS.ROOTS_DASHBOARD}/training/courses/details/${id}`,
    },
    specifications: {
      root: `${ROOTS.ROOTS_DASHBOARD}/training/specifications`,
      new: `${ROOTS.ROOTS_DASHBOARD}/training/specifications/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/training/specifications/${id}/edit`,
    },
    references: {
      root: `${ROOTS.ROOTS_DASHBOARD}/training/references`,
      new: `${ROOTS.ROOTS_DASHBOARD}/training/references/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/training/references/${id}/edit`,
    },
    exercises: {
      root: `${ROOTS.ROOTS_DASHBOARD}/training/exercises`,
      new: `${ROOTS.ROOTS_DASHBOARD}/training/exercises/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/training/exercises/${id}/edit`,
    },
    assign: {
      root: `${ROOTS.ROOTS_DASHBOARD}/training/courses/assign`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/training/courses/assign/${id}/edit`,
    },
    activities: {
      root: `${ROOTS.ROOTS_DASHBOARD}/activities`,
      new: `${ROOTS.ROOTS_DASHBOARD}/activities/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/activities/${id}/edit`,
      details: (id: any) => `${ROOTS.ROOTS_DASHBOARD}/activities/details/${id}`,
    },
    grantedLeave: {
      root: `${ROOTS.ROOTS_DASHBOARD}/granted-leave`,
      history: `${ROOTS.ROOTS_DASHBOARD}/granted-leave/history`,
      grantLeave: `${ROOTS.ROOTS_DASHBOARD}/granted-leave/grant-leave`,
    },
    leave: {
      root: `${ROOTS.ROOTS_DASHBOARD}/leave`,
      allLeaves: `${ROOTS.ROOTS_DASHBOARD}/leave/all-leaves`,
      new: `${ROOTS.ROOTS_DASHBOARD}/leave/create`,
      apply: `${ROOTS.ROOTS_DASHBOARD}/leave/apply`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/leave/${id}/edit`,
      details: (id: any) => `${ROOTS.ROOTS_DASHBOARD}/leave/leave-application/${id}`,
    },
    holiday: {
      root: `${ROOTS.ROOTS_DASHBOARD}/holiday`,
      new: `${ROOTS.ROOTS_DASHBOARD}/holiday/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/holiday/${id}/edit`,
      details: (id: any) => `${ROOTS.ROOTS_DASHBOARD}/holiday/details/${id}`,
    },
    attendance: {
      root: `${ROOTS.ROOTS_DASHBOARD}/attendance`,
      details: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/attendance/request/details/${id}`,
      detailsWFH: (id: string) =>
        `${ROOTS.ROOTS_DASHBOARD}/attendance/request/work-from-home/details/${id}`,
      detailsCompOff: (id: string) =>
        `${ROOTS.ROOTS_DASHBOARD}/attendance/request/comp-off/details/${id}`,
      view: (view: string) => `${ROOTS.ROOTS_DASHBOARD}/attendance?view=${view}`,
    },
    Performer: {
      root: `${ROOTS.ROOTS_DASHBOARD}/performer`,
    },
    salarySlip: {
      root: `${ROOTS.ROOTS_DASHBOARD}/salary-slip`,
      new: `${ROOTS.ROOTS_DASHBOARD}/salary-slip/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/salary-slip/${id}/edit`,
      details: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/salary-slip/details/${id}`,
    },
    master: {
      root: `${ROOTS.ROOTS_DASHBOARD}/master`,
    },
    job: {
      root: `${ROOTS.ROOTS_DASHBOARD}/job`,
      new: `${ROOTS.ROOTS_DASHBOARD}/job/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/job/${id}/edit`,
      details: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/job/details/${id}`,
    },
    blog: {
      root: `${ROOTS.ROOTS_DASHBOARD}/blog`,
      new: `${ROOTS.ROOTS_DASHBOARD}/blog/create`,
      edit: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/blog/${id}/edit`,
      details: (id: string) => `${ROOTS.ROOTS_DASHBOARD}/blog/details/${id}`,
    },
    three: `${ROOTS.ROOTS_DASHBOARD}/three`,
    group: {
      root: `${ROOTS.ROOTS_DASHBOARD}/group`,
      five: `${ROOTS.ROOTS_DASHBOARD}/group/five`,
      six: `${ROOTS.ROOTS_DASHBOARD}/group/six`,
    },
  },
};
