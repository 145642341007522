import { InitialState } from '../type';

export const GET_HOLIDAY = {
  pending: (state: InitialState, action: any) => {
    state.get.isLoading = true;
    state.get.isSuccess = false;
    state.get.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.get.list = action.payload.data.data.holiday;
    state.get.isLoading = false;
    state.get.isSuccess = true;
    state.get.isError = false;
  },
  rejected: (state: InitialState, action: any) => {
    state.get.isLoading = false;
    state.get.isSuccess = false;
    state.get.isError = true;
    state.get.errorMessage = action?.payload?.data?.message;
  },
};

export const GET_OPTIONAL_HOLIDAY = {
  pending: (state: InitialState, action: any) => {
    state.get.isLoading = true;
    state.get.isSuccess = false;
    state.get.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.get.optionalHoliday = action.payload.data.data.holiday;
    state.get.isLoading = false;
    state.get.isSuccess = true;
    state.get.isError = false;
  },
  rejected: (state: InitialState, action: any) => {
    state.get.isLoading = false;
    state.get.isSuccess = false;
    state.get.isError = true;
    state.get.errorMessage = action?.payload?.data?.message;
  },
};
