import { InitialState } from '../type';

export const MASTER = {
  pending: (state: InitialState, action: any) => {
    state.isLoading = true;
    state.isSuccess = false;
    state.isError = false;
    state.errorMessage = '';
  },
  fulfilled: (state: InitialState, action: any) => {
    switch (Object.keys(action.payload.data.data)?.[0]) {
      case 'employmentStatus':
        state.employmentStatus = action.payload.data.data.employmentStatus;
        break;
      case 'department':
        state.department = action.payload.data.data.department;
        break;
      case 'designation':
        state.designation = action.payload.data.data.designation;
        break;
      case 'bloodGroup':
        state.bloodGroup = action.payload.data.data.bloodGroup;
        break;
      case 'shiftType':
        state.shiftType = action.payload.data.data.shiftType;
        break;
      case 'gender':
        state.gender = action.payload.data.data.gender;
        break;
      case 'nationality':
        state.nationality = action.payload.data.data.nationality;
        break;
      case 'teamLead':
        state.teamLead = action.payload.data.data.teamLead;
        break;
      case 'countries':
        state.countries = action.payload.data.data.countries;
        break;
      case 'states':
        state.states = action.payload.data.data.states;
        break;
      case 'cities':
        state.city = action.payload.data.data.cities;
        break;
      case 'holidayType':
        state.holidayType = action.payload.data.data.holidayType;
        break;
      case 'assetsStatus':
        state.assetStatus = action.payload.data.data.assetsStatus;
        break;
      case 'assetType':
        state.assetType = action.payload.data.data.assetType;
        break;
      case 'leaveType':
        state.leaveType = action.payload.data.data.leaveType;
        break;
      case 'leaveDayType':
        state.leaveDayType = action.payload.data.data.leaveDayType;
        break;
      case 'permission':
        state.permission = action.payload.data.data.permission;
        break;
      case 'leaveStatus':
        state.leaveStatus = action.payload.data.data.leaveStatus;
        break;
      case 'grantLeave':
        state.grantLeave = action.payload.data.data.grantLeave;
        break;
      case 'lastInsertEmpCode':
        state.lastInsertEmpCode = action.payload.data.data.lastInsertEmpCode;
        break;
      case 'maritalStatus':
        state.maritalStatus = action.payload.data.data.maritalStatus;
        break;
      case 'zodiacSigns':
        state.zodiacSigns = action.payload.data.data.zodiacSigns;
        break;
      case 'color':
        state.color = action.payload.data.data.color;
        break;
      default:
        break;
    }
    state.isLoading = false;
    state.isSuccess = true;
    state.isError = false;
    state.errorMessage = '';
  },
  rejected: (state: InitialState, action: any) => {
    state.isLoading = false;
    state.isSuccess = false;
    state.isError = true;
    state.errorMessage = action.error;
  },
};
