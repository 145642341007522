/* eslint-disable class-methods-use-this */
import { workTypeEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class WorkTypeService {
  get(queryParams: any) {
    return commonAPI.get(`/${workTypeEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${workTypeEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${workTypeEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${workTypeEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${workTypeEndPoint}/${id}`);
  }
}

export default new WorkTypeService();
