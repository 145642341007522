/* eslint-disable class-methods-use-this */
import { jobTypeEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class JobTypeService {
  get(queryParams: any) {
    return commonAPI.get(`/${jobTypeEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${jobTypeEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${jobTypeEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${jobTypeEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${jobTypeEndPoint}/${id}`);
  }
}

export default new JobTypeService();
