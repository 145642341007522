export const authEndPoint = 'auth';
export const loginEndPoint = 'logIn';
export const adminEndPoint = 'admin';
export const changePasswordEndPoint = 'changePassword';
export const forgotPasswordEndPoint = 'forgotPassword';
export const setPasswordEndPoint = 'setPassword';
export const logoutEndPoint = 'logout';

export const bankEndPoint = 'bank';

export const permissionEndPoint = 'permission';

export const notificationEndPoint = 'notification';

export const attendanceEndPoint = 'attendance';
export const importSheetEndPoint = 'importSheet';
export const attendanceRequestEndPoint = 'request';
export const attendanceStatusEndPoint = 'status';
export const checkInOutEndPoint = 'check-in-out';
export const attendanceHistoryEndPoint = 'history';
export const attendanceAdminEndPoint = 'attendance/admin';

export const bioEndPoint = 'bio';

export const companyDocumentEndPoint = 'companyDocument';
export const companyDocumentAdminEndPoint = 'companyDocument/admin';

export const companyPolicyEndPoint = 'companyPolicies';
export const companyPolicyAdminEndPoint = 'companyPolicies/admin';

export const companyDriveEndPoint = 'companyDrive';
export const companyDriveAdminEndPoint = 'companyDrive/admin';

export const companyActivitiesEndPoint = 'companyActivities';
export const companyActivitiesAdminEndPoint = 'companyActivities/admin';

export const companyTrainingEndPoint = 'companyTraining';
export const companyTrainingAdminEndPoint = 'companyTraining/admin';

export const companyHelpEndPoint = 'help';
export const companyHelpAdminEndPoint = 'help/admin';

export const holidayEndPoint = 'holiday';
export const holidayAdminEndPoint = 'holiday/admin';

export const assetsEndPoint = 'asset';
export const assetsAdminEndPoint = 'asset/admin';

export const assetTypeEndPoint = 'assetType';
export const assetTypeAdminEndPoint = 'assetType';

export const leaveEndPoint = 'leave';
export const checkDatesEndPoint = 'checkDates';
export const leaveBalanceEndPoint = 'balance';
export const leaveHistoryEndPoint = 'history';
export const leaveAdminEndPoint = 'leave/admin';

export const grantLeaveEndPoint = 'grantLeaveAssign';
export const grantLeaveAdminEndPoint = 'grantLeaveAssign/admin';

export const employeeEndPoint = 'employee';
export const employeeAdminEndPoint = 'employee/admin';

export const activitiesEndPoint = 'companyActivities';
export const activitiesAdminEndPoint = 'companyActivities/admin';

export const policiesEndPoint = 'companyPolicies';
export const policiesAdminEndPoint = 'companyPolicies/admin';

export const documentEndPoint = 'companyDocument';
export const documentAdminEndPoint = 'companyDocument/admin';

export const driveEndPoint = 'companyDrive';
export const driveAdminEndPoint = 'companyDrive/admin';

export const courseEndPoint = 'course';
// export const courseAdminEndPoint = 'course/admin';
export const courseAdminEndPoint = 'course/admin';

export const courseAssignEndPoint = 'courseAssign';

export const specificationEndPoint = 'specification';
// export const specificationAdminEndPoint = 'specification/admin';
export const specificationAdminEndPoint = 'specification';

export const topicEndPoint = 'topic';
export const topicAdminEndPoint = 'topic';

export const subtopicEndPoint = 'subtopic';
export const subtopicAdminEndPoint = 'subtopic';

export const referenceEndPoint = 'reference';
export const referenceAdminEndPoint = 'reference';

export const courseSeqNumber = 'course/seqNumber';

export const salarySlipEndPoint = 'salarySlip';
export const salaryCalculateEndPoint = 'salaryCalculate';

export const exerciseEndPoint = 'exercise';
export const exerciseAdminEndPoint = 'exercise';

export const homeEndPoint = 'home';
export const homeAdminEndPoint = 'home';

export const feedEndPoint = 'feed';

export const postEndPoint = 'post';

export const praiseEndPoint = 'praise';

export const jobEndPoint = 'job';

export const jobTypeEndPoint = 'jobType';
export const workTypeEndPoint = 'workType';

export const blogTagEndPoint = 'blogTag';
export const blogTagAdminEndPoint = 'blogTag/admin';

export const blogEndPoint = 'blog';
export const blogPublishEndPoint = 'blogPublish';
export const blogAdminEndPoint = 'blog/admin';

export const masterEndPoint = 'master';
export const employmentStatusMasterEndPoint = 'employmentStatus';
export const blogCategoryMasterEndPoint = 'blogCategory';
export const blogStatusMasterEndPoint = 'blogStatus';
export const departmentMasterEndPoint = 'department';
export const designationMasterEndPoint = 'designation';
export const bloodGroupMasterEndPoint = 'bloodGroup';
export const requestTypeMasterEndPoint = 'requestType';
export const genderMasterEndPoint = 'gender';
export const nationalityMasterEndPoint = 'nationality';
export const countriesMasterEndPoint = 'countries';
export const lastInsertEmpCodeMasterEndPoint = 'lastInsertEmpCode';
export const teamLeadMasterEndPoint = 'teamLead';
export const statesMasterEndPoint = 'states';
export const byCountryCodeMasterEndPoint = 'byCountryCode';
export const citiesMasterEndPoint = 'cities';
export const leaveTypeMasterEndPoint = 'leaveType';
export const leaveDayTypeMasterEndPoint = 'leaveDayType';
export const leaveStatusMasterEndPoint = 'leaveStatus';
export const grantLeaveMasterEndPoint = 'grantLeave';
export const holidayTypeMasterEndPoint = 'holidayType';
export const assetStatusMasterEndPoint = 'assetStatus';
export const shiftTypeMasterEndPoint = 'shiftType';
export const martialStatusEndPoint = 'maritalStatus';
export const zodiacSignsEndPoint = 'zodiacSigns';
export const colorEndPoint = 'color';
export const permissionMasterEndPoint = 'permission';
export const postTypeMasterEndPoint = 'postType';
