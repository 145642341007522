import { InitialState } from '../type';

export const GET_LEAVE = {
  pending: (state: InitialState, action: any) => {
    state.get.isLoading = true;
    state.get.isSuccess = false;
    state.get.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    const payload = action.payload?.data?.data ? action.payload?.data?.data : action.payload;
    state.get.totalItems = payload?.totalItems;
    state.get.currentPage = payload?.currentPage;
    state.get.totalPages = payload?.totalPages;
    state.get.leaveDetails = payload?.getGrantLeave?.[0];
    state.get.leaveList = payload?.leave || [];
    // state.get.employeeLeave = action.payload.data.data.leave;
    // state.get.grantLeave = action.payload.data.data.getGrantLeave?.[0];
    state.get.isLoading = false;
    state.get.isSuccess = true;
    state.get.isError = false;
  },
  rejected: (state: InitialState, action: any) => {
    state.get.isLoading = false;
    state.get.isSuccess = false;
    state.get.isError = true;
    state.get.errorMessage = action.payload?.data?.data
      ? action?.payload?.data?.message
      : action?.payload?.message;
  },
};
