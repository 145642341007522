import { setPasswordEndPoint } from 'src/api/api-end-points';
import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../services/auth.service';

export const setPassword = createAsyncThunk('auth-set-password', async (payload: any, thunkAPI) => {
  try {
    const data = { ...payload };
    delete data.token;
    const response = await authService.post(`${setPasswordEndPoint}/${payload.token}`, data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
