import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getDrive } from '../thunk/get/getDrive';
import { getDriveById } from '../thunk/get/getDriveById.thunk';
import { GET_DRIVE } from './getSlices/getDrive.get';
import { GET_DRIVE_BY_ID } from './getSlices/getDriveById.get';

export const DriveSlice = createSlice({
  name: 'DriveSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET ASSETS
    [`${getDrive.pending}`]: GET_DRIVE.pending,
    [`${getDrive.fulfilled}`]: GET_DRIVE.fulfilled,
    [`${getDrive.rejected}`]: GET_DRIVE.rejected,

    // GET ASSET BY ID
    [`${getDriveById.pending}`]: GET_DRIVE_BY_ID.pending,
    [`${getDriveById.fulfilled}`]: GET_DRIVE_BY_ID.fulfilled,
    [`${getDriveById.rejected}`]: GET_DRIVE_BY_ID.rejected,
  },
});

export default DriveSlice.reducer;
