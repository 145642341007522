/* eslint-disable class-methods-use-this */
import { homeEndPoint, feedEndPoint, postEndPoint, praiseEndPoint } from 'src/api/api-end-points';
import commonAPI from '../../../api/common';

class HomeService {
  get() {
    return commonAPI.get(`/${homeEndPoint}`);
  }

  getFeed() {
    return commonAPI.get(`/${feedEndPoint}`);
  }

  postPost(data: any) {
    return commonAPI.post(`/${postEndPoint}`, data);
  }

  postPraise(data: any) {
    return commonAPI.post(`/${praiseEndPoint}`, data);
  }
}

export default new HomeService();
