import { InitialState } from '../type';

export const GET_HOME = {
  pending: (state: InitialState, action: any) => {
    state.get.isLoading = true;
    state.get.isSuccess = false;
    state.get.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.get.dailyLogs = action.payload.data.data.dailyLogs;
    state.get.onLeaveToday = action.payload.data.data.onLeaveToday;
    state.get.speedyImmencer = action.payload.data.data.speedyImmencer;
    state.get.dedicatedImmencer = action.payload.data.data.dedicatedImmencer;
    state.get.upcomingActivities = action.payload.data.data.upcomingActivities;
    state.get.upcomingBirthDays = action.payload.data.data.upcomingBirthDays;
    state.get.upcomingHolidays = action.payload.data.data.upcomingHolidays;
    state.get.isLoading = false;
    state.get.isSuccess = true;
    state.get.isError = false;
  },
  rejected: (state: InitialState, action: any) => {
    state.get.isLoading = false;
    state.get.isSuccess = false;
    state.get.isError = true;
    state.get.errorMessage = action?.payload?.data?.message;
  },
};
