// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
// utils
import { fDate, fToNow } from 'src/utils/format-time';
// components
import Label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
import { API_URL, API_VERSION } from 'src/utils/const';
import { useDispatch } from 'src/redux/store';
import { useTheme } from '@mui/material';
import { putNotification } from 'src/redux/notification/thunk/putNotification.thunk';
import { getNotification } from 'src/redux/notification/thunk/getNotification.thunk';
import { useSnackbar } from 'src/components/snackbar';
import { useBoolean } from 'src/hooks/useBoolean';
import { getNotificationById } from 'src/redux/notification/thunk/getNotificationById.thunk';
import { useNotificationPopups } from 'src/hooks/useNotificationsPopups';
// ----------------------------------------------------------------------

type NotificationItemProps = {
  notification: {
    filter(arg0: (item: any) => boolean): unknown;
    id: string;
    title: string;
    category: string;
    description: string;
    createdAt: Date;
    isUnRead: boolean;
    type: string;
    avatarUrl: string | null;
    toEmployeeAvatar: string | null;
    byEmployeeAvatar: string | null;
    byEmployeeName: string | null;
    toEmployeeName: string | null;
    notificationTypeName: string;
    isRead: any;
  };
};

export default function NotificationItem({ notification }: NotificationItemProps) {
  const drawer = useBoolean();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const notificationPopups = useNotificationPopups();

  const notificationTitle: any =
    (notification?.notificationTypeName === 'Post' &&
      `New Post from <strong>${notification?.byEmployeeName}</strong>`) ||
    (notification?.notificationTypeName === 'Praise' &&
      `<strong>${notification?.byEmployeeName}</strong> praised you! 👏🏻`) ||
    (notification?.notificationTypeName === 'Birthday' &&
      `<strong>${notification?.byEmployeeName}</strong>‘s birthday today! 🎂`) ||
    (notification?.notificationTypeName === 'Speedy Immencer' &&
      `<strong>${notification?.byEmployeeName}</strong> is ${notification?.title}`) ||
    (notification?.notificationTypeName === 'Dedicated immencer' &&
      `<strong>${notification?.byEmployeeName}</strong> is ${notification?.title}`);

  const imgAvatar =
    (notification?.notificationTypeName === 'Praise' && notification?.byEmployeeAvatar) ||
    (notification?.notificationTypeName === 'Dedicated immencer' &&
      notification?.byEmployeeAvatar) ||
    (notification?.notificationTypeName === 'Post' && notification?.byEmployeeAvatar) ||
    (notification?.notificationTypeName === 'Birthday' && notification?.byEmployeeAvatar) ||
    (notification?.notificationTypeName === 'Speedy Immencer' && notification?.byEmployeeAvatar);

  const unreadNotification = notification.isRead === 0;

  const onTabClick = async (notiId: any) => {
    const payload = {
      id: notiId,
      data: {
        isRead: 1,
      },
    };
    try {
      await dispatch(getNotificationById(payload.id)).then((x: any) => {
        if (x.payload?.data?.isSuccess) {
          notificationPopups.saveBirthDayData(x.payload?.data?.data.notification);
          notificationPopups.savePraiseData(x.payload?.data?.data.notification, 0);
        } else {
          console.log('error');
        }
      });

      if (notification.isRead === 0) {
        await dispatch(putNotification(payload)).then((x: any) => {
          if (x.payload?.data?.isSuccess) {
            // drawer.onFalse();
            // enqueueSnackbar((x.payload as any)?.data?.message);
            dispatch(getNotification(''));
          } else {
            // enqueueSnackbar(
            //   (x.payload as any)?.response?.data?.message || 'Something Went Wrong!',
            //   {
            //     variant: 'warning',
            //   }
            // );
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderAvatar = (
    <ListItemAvatar>
      {notification.byEmployeeAvatar ? (
        <Avatar
          src={`${API_URL}${API_VERSION}/${imgAvatar}`}
          sx={{ bgcolor: 'background.neutral' }}
        />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: 'background.neutral',
          }}
        >
          <Box
            component="img"
            src={`/assets/icons/notification/${
              (notification.type === 'order' && 'ic_order') ||
              (notification.type === 'chat' && 'ic_chat') ||
              (notification.type === 'mail' && 'ic_mail') ||
              (notification.type === 'delivery' && 'ic_delivery')
            }.svg`}
            sx={{ width: 24, height: 24 }}
          />
        </Stack>
      )}
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(notificationTitle)}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{ width: 2, height: 2, bgcolor: 'currentColor', mx: 0.5, borderRadius: '50%' }}
            />
          }
        >
          {fDate(notification.createdAt, 'dd MMM yyyy')}
        </Stack>
      }
    />
  );

  const renderUnReadBadge = unreadNotification && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'success.main',
        position: 'absolute',
      }}
    />
  );

  const friendAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button size="small" variant="contained">
        Accept
      </Button>
      <Button size="small" variant="outlined">
        Decline
      </Button>
    </Stack>
  );

  const projectAction = (
    <Stack alignItems="flex-start">
      <Box
        sx={{
          p: 1.5,
          my: 1.5,
          borderRadius: 1.5,
          color: 'text.secondary',
          bgcolor: 'background.neutral',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {reader(`${notification.description}`)}
      </Box>

      {/* <Button size="small" variant="contained">
        Reply
      </Button> */}
    </Stack>
  );

  const fileAction = (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        pl: 1,
        p: 1.5,
        mt: 1.5,
        borderRadius: 1.5,
        bgcolor: 'background.neutral',
      }}
    >
      <FileThumbnail
        file="http://localhost:8080/httpsdesign-suriname-2015.mp3"
        sx={{ width: 40, height: 40 }}
      />

      <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} flexGrow={1} sx={{ minWidth: 0 }}>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }} noWrap>
              design-suriname-2015.mp3
            </Typography>
          }
          secondary={
            <Stack
              direction="row"
              alignItems="center"
              sx={{ typography: 'caption', color: 'text.disabled' }}
              divider={
                <Box
                  sx={{
                    mx: 0.5,
                    width: 2,
                    height: 2,
                    borderRadius: '50%',
                    bgcolor: 'currentColor',
                  }}
                />
              }
            >
              <span>2.3 GB</span>
              <span>30 min ago</span>
            </Stack>
          }
        />

        <Button size="small" variant="outlined">
          Download
        </Button>
      </Stack>
    </Stack>
  );

  const tagsAction = (
    <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{ mt: 1.5 }}>
      <Label variant="outlined" color="info">
        Design
      </Label>
      <Label variant="outlined" color="warning">
        Dashboard
      </Label>
      <Label variant="outlined">Design system</Label>
    </Stack>
  );

  const paymentAction = (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button size="small" variant="contained">
        Pay
      </Button>
      <Button size="small" variant="outlined">
        Decline
      </Button>
    </Stack>
  );

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme_: any) => `dashed 1px ${theme_.palette.divider}`,
      }}
      onClick={() => {
        onTabClick(notification?.id);
      }}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
        {/* {notification.type === 'friend' && friendAction} */}
        {(notification?.notificationTypeName === 'Speedy Immencer' ||
          notification?.notificationTypeName === 'Post' ||
          notification?.notificationTypeName === 'Praise') &&
          projectAction}
        {/* {notification.type === 'file' && fileAction} */}
        {/* {notification.type === 'tags' && tagsAction} */}
        {/* {notification.type === 'payment' && paymentAction} */}
      </Stack>
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function reader(data: string | any) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle1' },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    />
  );
}
