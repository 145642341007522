/* eslint-disable class-methods-use-this */
import { jobEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class JobService {
  get(queryParams: any) {
    return commonAPI.get(`/${jobEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${jobEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${jobEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${jobEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${jobEndPoint}/${id}`);
  }
}

export default new JobService();
