/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { exerciseAdminEndPoint, exerciseEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class ExerciseService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${exerciseAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${exerciseEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${exerciseEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${exerciseEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${exerciseEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${exerciseEndPoint}/${id}`);
  }
}

export default new ExerciseService();
