import { InitialState } from '../type';

export const GET_REQUEST_BY_ID = {
  pending: (state: InitialState, action: any) => {
    state.getRequest.isLoading = true;
    state.getRequest.isSuccess = false;
    state.getRequest.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.getRequest.totalItems = action?.payload?.data?.totalItems;
    state.getRequest.requestById = action.payload.data?.data?.request;
    state.getRequest.isLoading = false;
    state.getRequest.isSuccess = true;
    state.getRequest.isError = false;
  },
  rejected: (state: InitialState, action: any) => {
    state.getRequest.isLoading = false;
    state.getRequest.isSuccess = false;
    state.getRequest.isError = true;
    state.getRequest.errorMessage = action?.payload?.data?.message;
  },
};
