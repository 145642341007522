import { InitialState } from '../type';

export const EMPLOYEE_PAYLOAD = {
  pending: (state: any, action: any) => {
    state.post.isLoading = true;
    state.post.isSuccess = false;
    state.post.isError = false;
  },
  fulfilled: (state: any, action: any) => {
    state.post.employeePayload =
      action.payload !== undefined ? { ...state.post.employeePayload, ...action.payload } : {};
    state.post.isLoading = false;
    state.post.isSuccess = true;
    state.post.isError = false;
  },
  rejected: (state: any, action: any) => {
    state.post.isLoading = false;
    state.post.isSuccess = false;
    state.post.isError = true;
    state.post.errorMessage = action.error;
  },
};
