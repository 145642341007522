/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { blogAdminEndPoint, blogEndPoint, blogPublishEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class BlogService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${blogAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${blogEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${blogEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${blogEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${blogEndPoint}/${id}`, data);
  }

  putBlogPublish(id: any, data: any) {
    return commonAPI.put(`/${blogPublishEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${blogEndPoint}/${id}`);
  }
}

export default new BlogService();
