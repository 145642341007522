/* eslint-disable class-methods-use-this */
import { authEndPoint, logoutEndPoint } from 'src/api/api-end-points';
import commonAPI from 'src/api/common';

class AuthService {
  post(endPoint: string, data: any) {
    return commonAPI.post(`/${authEndPoint}/${endPoint}`, data);
  }

  postLogout() {
    return commonAPI.post(`/${authEndPoint}/${logoutEndPoint}`);
  }
}

export default new AuthService();
