import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getAttendance, getAttendanceList } from '../thunk/get/getAttendance.thunk';
import { postAttendance } from '../thunk/post/postAttendance.thunk';
import { initialState } from './initialState';
import { GET_ATTENDANCE } from './getSlices/getAttendance.get';
import { GET_ATTENDANCE_LIST } from './getSlices/getAttendanceList.get';
import { POST_ATTENDANCE } from './postSlices/postAttendance.post';
import { GET_ATTENDANCE_STATUS } from './getSlices/getAttendanceStatus.get';
import { getAttendanceStatus } from '../thunk/get/getAttendanceStatus.thunk';
import { getRequestById } from '../thunk/get/getRequestById.thunk';
import { GET_REQUEST_BY_ID } from './getSlices/getRequestById.get';
import { GET_REQUEST_OR_WFH_LIST } from './getSlices/getRequestOrWFHList.thunk';
import { getRequestOrWFHList } from '../thunk/get/getRequestOrWFHList.thunk';
import { getAttendanceLogByDate } from '../thunk/get/getAttendanceLogByDate.thunk';
import { GET_ATTENDANCE_LOG_BY_DATE } from './getSlices/getAttendanceLogByDate.get';

export const AttendanceSlices = createSlice({
  name: 'AttendanceSlices',
  initialState,
  reducers: {
    openCloseRegularizationDialog(state: any, action: any) {
      state.dialogBoxOpen = action.payload?.dialogBoxOpen;
      state.isRequest = action.payload?.isRequest;
      state.isEdit = action.payload?.isEdit;
    },
    openCloseWorkFromHomeDialog(state: any, action: any) {
      state.workFromHomeDialogBoxOpen = action.payload?.workFromHomeDialogBoxOpen;
      state.isRequest = action.payload?.isRequest;
      state.isEditWFH = action.payload?.isEditWFH;
    },
    openCloseCompOffDialog(state: any, action: any) {
      state.compOffDialogBoxOpen = action.payload?.compOffDialogBoxOpen;
      state.isRequest = action.payload?.isRequest;
      state.isEditCompOff = action.payload?.isEditCompOff;
    },
  },
  extraReducers: {
    // GET
    [`${getAttendance.pending}`]: GET_ATTENDANCE.pending,
    [`${getAttendance.fulfilled}`]: GET_ATTENDANCE.fulfilled,
    [`${getAttendance.rejected}`]: GET_ATTENDANCE.rejected,

    // Get Attendance Status
    [`${getAttendanceStatus.pending}`]: GET_ATTENDANCE_STATUS.pending,
    [`${getAttendanceStatus.fulfilled}`]: GET_ATTENDANCE_STATUS.fulfilled,
    [`${getAttendanceStatus.rejected}`]: GET_ATTENDANCE_STATUS.rejected,

    // Get Attendance
    [`${getAttendanceList.pending}`]: GET_ATTENDANCE_LIST.pending,
    [`${getAttendanceList.fulfilled}`]: GET_ATTENDANCE_LIST.fulfilled,
    [`${getAttendanceList.rejected}`]: GET_ATTENDANCE_LIST.rejected,

    // Get Attendance by date
    [`${getAttendanceLogByDate.pending}`]: GET_ATTENDANCE_LOG_BY_DATE.pending,
    [`${getAttendanceLogByDate.fulfilled}`]: GET_ATTENDANCE_LOG_BY_DATE.fulfilled,
    [`${getAttendanceLogByDate.rejected}`]: GET_ATTENDANCE_LOG_BY_DATE.rejected,

    // Get Request by id
    [`${getRequestById.pending}`]: GET_REQUEST_BY_ID.pending,
    [`${getRequestById.fulfilled}`]: GET_REQUEST_BY_ID.fulfilled,
    [`${getRequestById.rejected}`]: GET_REQUEST_BY_ID.rejected,

    // Get Request by id
    [`${getRequestOrWFHList.pending}`]: GET_REQUEST_OR_WFH_LIST.pending,
    [`${getRequestOrWFHList.fulfilled}`]: GET_REQUEST_OR_WFH_LIST.fulfilled,
    [`${getRequestOrWFHList.rejected}`]: GET_REQUEST_OR_WFH_LIST.rejected,

    // POST
    [`${postAttendance.pending}`]: POST_ATTENDANCE.pending,
    [`${postAttendance.fulfilled}`]: POST_ATTENDANCE.fulfilled,
    [`${postAttendance.rejected}`]: POST_ATTENDANCE.rejected,
  },
});

export const { openCloseRegularizationDialog }: any = AttendanceSlices.actions;
export const { openCloseWorkFromHomeDialog }: any = AttendanceSlices.actions;
export const { openCloseCompOffDialog }: any = AttendanceSlices.actions;

export default AttendanceSlices.reducer;
