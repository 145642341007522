/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import {
  adminEndPoint,
  salaryCalculateEndPoint,
  salarySlipEndPoint,
} from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class SalarySlipService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${salarySlipEndPoint}/${adminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${salarySlipEndPoint}${queryParams || ''}`);
  }

  getSalaryCalculation(queryParams: any) {
    return commonAPI.get(`/${salarySlipEndPoint}/${salaryCalculateEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${salarySlipEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${salarySlipEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${salarySlipEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${salarySlipEndPoint}/${id}`);
  }
}

export default new SalarySlipService();
