import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getBioDetails } from '../thunk/get/getBioDetails.thunk.';
import { GET_BIO } from './getSlices/bio.get';
import { putBio } from '../thunk/put/putBio.thunk';
import { PUT_BIO } from './putSlices/bio.put';

const bioSlice = createSlice({
  name: 'bioSlice',
  initialState,
  reducers: {
    setIsEditBio(state: any, action: any) {
      state.isEditBio = action.payload;
    },
  },
  extraReducers: {
    // GET BIO
    [`${getBioDetails.pending}`]: GET_BIO.pending,
    [`${getBioDetails.fulfilled}`]: GET_BIO.fulfilled,
    [`${getBioDetails.rejected}`]: GET_BIO.rejected,
  },

  // GET BIO
  [`${putBio.pending}`]: PUT_BIO.pending,
  [`${putBio.fulfilled}`]: PUT_BIO.fulfilled,
  [`${putBio.rejected}`]: PUT_BIO.rejected,
});

export const { setIsEditBio }: any = bioSlice.actions;

export default bioSlice.reducer;
