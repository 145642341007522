import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_BLOG_STATUS } from './get/getBlogStatus.get';
import { GET_BLOG_STATUS_BY_ID } from './get/getBlogStatusById.get';
import { getBlogStatus } from '../thunk/get/getBlogStatus.thunk';
import { getBlogStatusById } from '../thunk/get/getBlogStatusById.thunk';

export const BlogStatusSlice = createSlice({
  name: 'BlogStatusSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Blog Status
    [`${getBlogStatus.pending}`]: GET_BLOG_STATUS.pending,
    [`${getBlogStatus.fulfilled}`]: GET_BLOG_STATUS.fulfilled,
    [`${getBlogStatus.rejected}`]: GET_BLOG_STATUS.rejected,
    // GET Blog Status BY ID
    [`${getBlogStatusById.pending}`]: GET_BLOG_STATUS_BY_ID.pending,
    [`${getBlogStatusById.fulfilled}`]: GET_BLOG_STATUS_BY_ID.fulfilled,
    [`${getBlogStatusById.rejected}`]: GET_BLOG_STATUS_BY_ID.rejected,
  },
});

export default BlogStatusSlice.reducer;
