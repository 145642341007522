/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { specificationAdminEndPoint, specificationEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class SpecificationService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${specificationAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${specificationEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${specificationEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${specificationEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${specificationEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${specificationEndPoint}/${id}`);
  }
}

export default new SpecificationService();
