import { createSlice } from '@reduxjs/toolkit';
import { getPermission } from '../thunk/get/getPermission.thunk';
import { GET_PERMISSION } from './getPermission/getPermission.get';
import { initialState } from './initialState';
import { getPermissionById } from '../thunk/get/getPermissionById.thunk';
import { GET_PERMISSION_BY_ID } from './getPermission/getPermissionById.slice';

const PermissionSlice = createSlice({
  name: 'PermissionSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET
    [`${getPermission.pending}`]: GET_PERMISSION.pending,
    [`${getPermission.fulfilled}`]: GET_PERMISSION.fulfilled,
    [`${getPermission.rejected}`]: GET_PERMISSION.rejected,
    // GET By id
    [`${getPermissionById.pending}`]: GET_PERMISSION_BY_ID.pending,
    [`${getPermissionById.fulfilled}`]: GET_PERMISSION_BY_ID.fulfilled,
    [`${getPermissionById.rejected}`]: GET_PERMISSION_BY_ID.rejected,
  },
});

export default PermissionSlice.reducer;
