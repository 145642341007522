import { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
import { dispatch, useSelector } from 'src/redux/store';
import { isPermissionAllowed, isPermissionAllowedDashBoard } from 'src/utils/helper';
import { getPermission } from 'src/redux/permission/thunk/get/getPermission.thunk';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/One'));

// employee
const EmployeePage = lazy(() => import('src/pages/dashboard/employee/EmployeePage'));
const EmployeeDetails = lazy(() => import('src/pages/dashboard/employee/EmployeeDetails'));
const EmployeeCreatePage = lazy(() => import('src/pages/dashboard/employee/EmployeeCreatePage'));
const EmployeeEditPage = lazy(() => import('src/pages/dashboard/employee/EmployeeEditPage'));
const PermissionsPage = lazy(() => import('src/pages/dashboard/permissions/PermissionsPage'));
const AssetsPage = lazy(() => import('src/pages/dashboard/assets/AssetsPage'));
const AssetCreatePage = lazy(() => import('src/pages/dashboard/assets/AssetCreatePage'));
const AssetEditPage = lazy(() => import('src/pages/dashboard/assets/AssetEditPage'));
const AssetsDetailsView = lazy(() => import('src/pages/dashboard/assets/AssetsDetailsView'));
const DrivePage = lazy(() => import('src/pages/dashboard/drive/DrivePage'));
const DriveCreatePage = lazy(() => import('src/pages/dashboard/drive/DriveCreatePage'));
const DriveEditPage = lazy(() => import('src/pages/dashboard/drive/DriveEditPage'));
const DriveDetailsView = lazy(() => import('src/pages/dashboard/drive/DriveDetailsView'));
const DocumentPage = lazy(() => import('src/pages/dashboard/document/DocumentPage'));
const DocumentCreatePage = lazy(() => import('src/pages/dashboard/document/DocumentCreatePage'));
const DocumentEditPage = lazy(() => import('src/pages/dashboard/document/DocumentEditPage'));
const DocumentDetailsView = lazy(() => import('src/pages/dashboard/document/DocumentDetailsView'));
const PolicyPage = lazy(() => import('src/pages/dashboard/policy/PolicyPage'));
const PolicyCreatePage = lazy(() => import('src/pages/dashboard/policy/PolicyCreatePage'));
const PolicyEditPage = lazy(() => import('src/pages/dashboard/policy/PolicyEditPage'));
const PolicyDetailsView = lazy(() => import('src/pages/dashboard/policy/PolicyDetailsView'));
const CoursePage = lazy(() => import('src/pages/dashboard/training/CoursePage'));
const CourseCreatePage = lazy(() => import('src/pages/dashboard/training/CourseCreatePage'));
const CourseAssignPage = lazy(() => import('src/pages/dashboard/training/CourseAssignPage'));
const CourseEditAssignPage = lazy(
  () => import('src/pages/dashboard/training/CourseEditAssignPage')
);
const CourseEditPage = lazy(() => import('src/pages/dashboard/training/CourseEditPage'));
const CourseDetailsPage = lazy(() => import('src/pages/dashboard/training/CourseDetailsPage'));
const ActivitiesPage = lazy(() => import('src/pages/dashboard/activities/ActivitiesPage'));
const ActivitiesCreatePage = lazy(
  () => import('src/pages/dashboard/activities/ActivitiesCreatePage')
);
const ActivitiesEditPage = lazy(() => import('src/pages/dashboard/activities/ActivitiesEditPage'));
const ActivitiesDetailsView = lazy(
  () => import('src/pages/dashboard/activities/ActivitiesDetailsView')
);
const GrantLeavePage = lazy(() => import('src/pages/dashboard/grantLeave/GrantLeavePage'));
const CreateGrantLeave = lazy(() => import('src/pages/dashboard/grantLeave/CreateGrantLeave'));
const GrantLeaveHistoryPage = lazy(
  () => import('src/pages/dashboard/grantLeave/GrantLeaveHistoryPage')
);
const LeavePage = lazy(() => import('src/pages/dashboard/leave/LeavePage'));
const AllLeavesPage = lazy(() => import('src/pages/dashboard/leave/AllLeavePage'));
const CreateLeavePage = lazy(() => import('src/pages/dashboard/leave/CreateLeavePage'));
const LeaveDetailsPage = lazy(() => import('src/pages/dashboard/leave/LeaveDetailsPage'));
const ApplyLeavePage = lazy(() => import('src/pages/dashboard/leave/ApplyLeavePage'));
const EditLeavePage = lazy(() => import('src/pages/dashboard/leave/EditLeavePage'));
const HolidayPage = lazy(() => import('src/pages/dashboard/holiday/HolidayPage'));
const HolidayCreatePage = lazy(() => import('src/pages/dashboard/holiday/HolidayCreatePage'));
const HolidayEditPage = lazy(() => import('src/pages/dashboard/holiday/HolidayEditPage'));
const HolidayDetailsPage = lazy(() => import('src/pages/dashboard/holiday/HolidayDetailsPage'));
const JobPage = lazy(() => import('src/pages/dashboard/job/JobPage'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/JobCreatePage'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/JobEditPage'));
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/JobDetailsPage'));
const AttendancePage = lazy(() => import('src/pages/dashboard/attendance/AttendancePage'));
const PerformerPage = lazy(() => import('src/pages/dashboard/performer/PerformerPage'));

const RegularizationRequestDetailsPage = lazy(
  () => import('src/pages/dashboard/attendance/RegularizationRequestDetailsPage')
);
const WorkFromHomeRequestDetailsPage = lazy(
  () => import('src/pages/dashboard/attendance/WorkFromHomeRequestDetailsPage')
);
const CompOffRequestDetailsPage = lazy(
  () => import('src/pages/dashboard/attendance/CompOffRequestDetailsPage')
);
const SalarySlipPage = lazy(() => import('src/pages/dashboard/salarySlip/SalarySlipPage'));
const SalarySlipEditPage = lazy(() => import('src/pages/dashboard/salarySlip/SalarySlipEditPage'));
const SalarySlipCreatePage = lazy(
  () => import('src/pages/dashboard/salarySlip/SalarySlipCreatePage')
);
const SalarySlipDetailPage = lazy(
  () => import('src/pages/dashboard/salarySlip/SalarySlipDetailPage')
);
const MasterSettingPage = lazy(() => import('src/pages/dashboard/master/MasterSettingPage'));
const BlogPage = lazy(() => import('src/pages/dashboard/blog/BlogPage'));
const BlogCreatePage = lazy(() => import('src/pages/dashboard/blog/BlogCreatePage'));
const BlogEditPage = lazy(() => import('src/pages/dashboard/blog/BlogEditPage'));
const BlogDetailsPage = lazy(() => import('src/pages/dashboard/blog/BlogDetailsPage'));
const SpecificationsPage = lazy(
  () => import('src/pages/dashboard/specifications/SpecificationsPage')
);
const SpecificationsCreatePage = lazy(
  () => import('src/pages/dashboard/specifications/SpecificationsCreatePage')
);
const SpecificationsEditPage = lazy(
  () => import('src/pages/dashboard/specifications/SpecificationsEditPage')
);
const ReferencesPage = lazy(() => import('src/pages/dashboard/references/ReferencesPage'));
const ReferencesCreatePage = lazy(
  () => import('src/pages/dashboard/references/ReferencesCreatePage')
);
const ReferencesEditPage = lazy(() => import('src/pages/dashboard/references/ReferencesEditPage'));
const ExercisesPage = lazy(() => import('src/pages/dashboard/exercises/ExercisesPage'));
const ExercisesCreatePage = lazy(() => import('src/pages/dashboard/exercises/ExercisesCreatePage'));
const ExercisesEditPage = lazy(() => import('src/pages/dashboard/exercises/ExercisesEditPage'));

// ----------------------------------------------------------------------

export const dashboardRoutes = (): Array<any> | null => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [permit, setPermit] = useState<any>({
    viewPermission: true,
    editPermission: true,
    addPermission: true,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { permissionData, isAdmin, modules, moduleCategory } = useSelector(
    (state: any) => state.permissions?.get
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const adminView = useSelector((state: any) => state.auth.adminView);
  const adminTheme = isAdmin && adminView;

  const modalName = window.location.pathname;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (permissionData?.length > 0) {
      const viewPermission = isPermissionAllowedDashBoard(permissionData, modalName, 'view');
      const editPermission = isPermissionAllowed(adminTheme, permissionData, modalName, 'edit');
      const addPermission = isPermissionAllowed(adminTheme, permissionData, modalName, 'add');
      setPermit({ viewPermission, editPermission, addPermission });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionData, modalName]);

  return [
    {
      path: 'home',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <IndexPage />, index: true },
        {
          path: 'employee',
          children: [
            permit.viewPermission ? { element: <EmployeePage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <EmployeeCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <EmployeeEditPage /> } : {},
            permit.viewPermission ? { path: 'details/:id', element: <EmployeeDetails /> } : {},
          ],
        },
        // Assets Routes
        {
          path: 'assets',
          children: [
            permit.viewPermission ? { element: <AssetsPage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <AssetCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <AssetEditPage /> } : {},
            permit.viewPermission ? { path: 'details/:id', element: <AssetsDetailsView /> } : {},
          ],
        },

        {
          path: 'permissions',
          children: [{ element: <PermissionsPage />, index: true }],
        },
        {
          path: 'drive',
          children: [
            permit.viewPermission ? { element: <DrivePage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <DriveCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <DriveEditPage /> } : {},
            permit.viewPermission ? { path: 'details/:id', element: <DriveDetailsView /> } : {},
          ],
        },
        {
          path: 'document',
          children: [
            permit.viewPermission ? { element: <DocumentPage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <DocumentCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <DocumentEditPage /> } : {},
            permit.viewPermission ? { path: 'details/:id', element: <DocumentDetailsView /> } : {},
          ],
        },
        {
          path: 'policy',
          children: [
            permit.viewPermission ? { element: <PolicyPage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <PolicyCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <PolicyEditPage /> } : {},
            permit.viewPermission ? { path: 'details/:id', element: <PolicyDetailsView /> } : {},
          ],
        },
        {
          path: 'training/courses',
          children: [
            { element: <CoursePage />, index: true },
            { path: 'list', element: <CoursePage /> },
            { path: 'create', element: <CourseCreatePage /> },
            { path: ':id/edit', element: <CourseEditPage /> },
            { path: 'details/:id', element: <CourseDetailsPage /> },
          ],
        },
        {
          path: 'training/specifications',
          children: [
            permit.viewPermission ? { element: <SpecificationsPage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <SpecificationsCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <SpecificationsEditPage /> } : {},
          ],
        },
        {
          path: 'training/references',
          children: [
            permit.viewPermission ? { element: <ReferencesPage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <ReferencesCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <ReferencesEditPage /> } : {},
          ],
        },
        {
          path: 'training/Exercises',
          children: [
            permit.viewPermission ? { element: <ExercisesPage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <ExercisesCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <ExercisesEditPage /> } : {},
          ],
        },
        {
          path: 'training/courses/assign',
          children: [
            { element: <CourseAssignPage />, index: true },
            { path: ':id/edit', element: <CourseEditAssignPage /> },
          ],
        },
        {
          path: 'activities',
          children: [
            permit.viewPermission ? { element: <ActivitiesPage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <ActivitiesCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <ActivitiesEditPage /> } : {},
            permit.viewPermission
              ? { path: 'details/:id', element: <ActivitiesDetailsView /> }
              : {},
          ],
        },
        {
          path: 'leave',
          children: [
            permit.viewPermission ? { element: <LeavePage />, index: true } : {},
            { path: 'all-leaves', element: <AllLeavesPage /> },
            permit.addPermission ? { path: 'create', element: <CreateLeavePage /> } : {},
            { path: 'apply', element: <ApplyLeavePage /> },
            { path: ':id/edit', element: <EditLeavePage /> },
            permit.viewPermission
              ? { path: 'leave-application/:id', element: <LeaveDetailsPage /> }
              : {},
          ],
        },
        {
          path: 'granted-leave',
          children: [
            permit.viewPermission ? { element: <GrantLeavePage />, index: true } : {},
            permit.viewPermission ? { path: 'history', element: <GrantLeaveHistoryPage /> } : {},
            permit.addPermission ? { path: 'grant-leave', element: <CreateGrantLeave /> } : {},
          ],
        },
        {
          path: 'holiday',
          children: [
            permit.viewPermission ? { element: <HolidayPage />, index: true } : {},
            permit.addPermission ? { path: 'create', element: <HolidayCreatePage /> } : {},
            permit.editPermission ? { path: ':id/edit', element: <HolidayEditPage /> } : {},
            permit.viewPermission ? { path: 'details/:id', element: <HolidayDetailsPage /> } : {},
          ],
        },
        {
          path: 'attendance',
          children: [
            { element: <AttendancePage />, index: true },
            { path: 'request/details/:id', element: <RegularizationRequestDetailsPage /> },
            {
              path: 'request/work-from-home/details/:id',
              element: <WorkFromHomeRequestDetailsPage />,
            },
            {
              path: 'request/comp-off/details/:id',
              element: <CompOffRequestDetailsPage />,
            },
          ],
        },
        {
          path: 'performer',
          children: [{ element: <PerformerPage />, index: true }],
        },
        {
          path: 'salary-slip',
          children: [
            { element: <SalarySlipPage />, index: true },
            { path: 'create', element: <SalarySlipCreatePage /> },
            { path: ':id/edit', element: <SalarySlipEditPage /> },
            { path: 'details/:id', element: <SalarySlipDetailPage /> },
          ],
        },
        {
          path: 'master',
          children: [{ element: <MasterSettingPage />, index: true }],
        },
        {
          path: 'job',
          children: [
            { element: <JobPage />, index: true },
            { path: 'create', element: <JobCreatePage /> },
            { path: ':id/edit', element: <JobEditPage /> },
            { path: 'details/:id', element: <JobDetailsPage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <BlogPage />, index: true },
            { path: 'create', element: <BlogCreatePage /> },
            { path: ':id/edit', element: <BlogEditPage /> },
            { path: 'details/:id', element: <BlogDetailsPage /> },
          ],
        },
      ],
    },
  ];
};

// export const dashboardRoutes = [
//   {
//     path: 'home',
//     element: (
//       <AuthGuard>
//         <DashboardLayout>
//           <Suspense fallback={<LoadingScreen />}>
//             <Outlet />
//           </Suspense>
//         </DashboardLayout>
//       </AuthGuard>
//     ),
//     children: [
//       { element: <IndexPage />, index: true },
//       {
//         path: 'employee',
//         children: [
//           { element: <EmployeePage />, index: true },
//           { path: 'create', element: <EmployeeCreatePage /> },
//           { path: ':id/edit', element: <EmployeeEditPage /> },
//           { path: 'details/:id', element: <EmployeeDetails /> },
//         ],
//       },
//       {
//         path: 'assets',
//         children: [
//           { element: <AssetsPage />, index: true },
//           { path: 'create', element: <AssetCreatePage /> },
//           { path: ':id/edit', element: <AssetEditPage /> },
//           { path: 'details/:id', element: <AssetsDetailsView /> },
//         ],
//       },
//       {
//         path: 'permissions',
//         children: [{ element: <PermissionsPage />, index: true }],
//       },
//       {
//         path: 'drive',
//         children: [
//           { element: <DrivePage />, index: true },
//           { path: 'create', element: <DriveCreatePage /> },
//           { path: ':id/edit', element: <DriveEditPage /> },
//           { path: 'details/:id', element: <DriveDetailsView /> },
//         ],
//       },
//       {
//         path: 'document',
//         children: [
//           { element: <DocumentPage />, index: true },
//           { path: 'create', element: <DocumentCreatePage /> },
//           { path: ':id/edit', element: <DocumentEditPage /> },
//           { path: 'details/:id', element: <DocumentDetailsView /> },
//         ],
//       },
//       {
//         path: 'policy',
//         children: [
//           { element: <PolicyPage />, index: true },
//           { path: 'create', element: <PolicyCreatePage /> },
//           { path: ':id/edit', element: <PolicyEditPage /> },
//           { path: 'details/:id', element: <PolicyDetailsView /> },
//         ],
//       },
//       {
//         path: 'training/courses',
//         children: [
//           { element: <CoursePage />, index: true },
//           { path: 'list', element: <CoursePage /> },
//           { path: 'create', element: <CourseCreatePage /> },
//           // { path: 'assign', element: <CourseAssignPage /> },
//           { path: ':id/edit', element: <CourseEditPage /> },
//           { path: 'details/:id', element: <PolicyDetailsView /> },
//         ],
//       },
//       {
//         path: 'training/courses/assign',
//         children: [
//           { element: <CourseAssignPage />, index: true },
//           { path: ':id/edit', element: <CourseEditAssignPage /> },
//         ],
//       },
//       {
//         path: 'activities',
//         children: [
//           { element: <ActivitiesPage />, index: true },
//           { path: 'create', element: <ActivitiesCreatePage /> },
//           { path: ':id/edit', element: <ActivitiesEditPage /> },
//           { path: 'details/:id', element: <ActivitiesDetailsView /> },
//         ],
//       },
//       {
//         path: 'leave',
//         children: [
//           { element: <LeavePage />, index: true },
//           { path: 'all-leaves', element: <AllLeavesPage /> },
//           { path: 'create', element: <CreateLeavePage /> },
//           { path: 'apply', element: <ApplyLeavePage /> },
//           { path: ':id/edit', element: <EditLeavePage /> },
//           { path: 'leave-application/:id', element: <LeaveDetailsPage /> },
//         ],
//       },
//       {
//         path: 'granted-leave',
//         children: [
//           { element: <GrantLeavePage />, index: true },
//           { path: 'history', element: <GrantLeaveHistoryPage /> },
//           { path: 'grant-leave', element: <CreateGrantLeave /> },
//         ],
//       },
//       {
//         path: 'holiday',
//         children: [
//           { element: <HolidayPage />, index: true },
//           { path: 'create', element: <HolidayCreatePage /> },
//           { path: ':id/edit', element: <HolidayEditPage /> },
//           { path: 'details/:id', element: <HolidayDetailsPage /> },
//         ],
//       },
//       {
//         path: 'attendance',
//         children: [
//           { element: <AttendancePage />, index: true },
//           { path: 'request/details/:id', element: <RegularizationRequestDetailsPage /> },
//         ],
//       },
//     ],
//   },
// ];
