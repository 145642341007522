/* eslint-disable class-methods-use-this */
import { holidayTypeMasterEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class HolidayTypeService {
  get(queryParams: any) {
    return commonAPI.get(`/${holidayTypeMasterEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${holidayTypeMasterEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${holidayTypeMasterEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${holidayTypeMasterEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${holidayTypeMasterEndPoint}/${id}`);
  }
}

export default new HolidayTypeService();
