import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getReference } from '../thunk/get/getReference';
import { getReferenceById } from '../thunk/get/getReferenceById.thunk';
import { GET_REFERENCE } from './getSlices/getReference.get';
import { GET_REFERENCE_BY_ID } from './getSlices/getReferenceById.get';

export const ReferenceSlice = createSlice({
  name: 'ReferenceSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET REFERENCE
    [`${getReference.pending}`]: GET_REFERENCE.pending,
    [`${getReference.fulfilled}`]: GET_REFERENCE.fulfilled,
    [`${getReference.rejected}`]: GET_REFERENCE.rejected,

    // GET REFERENCE BY ID
    [`${getReferenceById.pending}`]: GET_REFERENCE_BY_ID.pending,
    [`${getReferenceById.fulfilled}`]: GET_REFERENCE_BY_ID.fulfilled,
    [`${getReferenceById.rejected}`]: GET_REFERENCE_BY_ID.rejected,
  },
});

export default ReferenceSlice.reducer;
