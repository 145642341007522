import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getLeaveType } from '../thunk/get/getLeaveType.thunk';
import { getLeaveTypeById } from '../thunk/get/getLeaveTypeById.thunk';
import { GET_LEAVE_TYPE } from './get/getLeaveType.get';
import { GET_LEAVE_TYPE_BY_ID } from './get/getLeaveTypeById.get';

export const LeaveTypeSlice = createSlice({
  name: 'LeaveTypeSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Holiday Type
    [`${getLeaveType.pending}`]: GET_LEAVE_TYPE.pending,
    [`${getLeaveType.fulfilled}`]: GET_LEAVE_TYPE.fulfilled,
    [`${getLeaveType.rejected}`]: GET_LEAVE_TYPE.rejected,
    // GET Holiday Type BY ID
    [`${getLeaveTypeById.pending}`]: GET_LEAVE_TYPE_BY_ID.pending,
    [`${getLeaveTypeById.fulfilled}`]: GET_LEAVE_TYPE_BY_ID.fulfilled,
    [`${getLeaveTypeById.rejected}`]: GET_LEAVE_TYPE_BY_ID.rejected,
  },
});

export default LeaveTypeSlice.reducer;
