import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_SHIFT_TYPE } from './get/getShiftType.get';
import { getShiftType } from '../thunk/get/getShiftType.thunk';
import { getShiftTypeById } from '../thunk/get/getShiftTypeById.thunk';
import { GET_SHIFT_TYPE_BY_ID } from './get/getShiftTypeById.get';

export const ShiftTypeSlice = createSlice({
  name: 'ShiftTypeSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Blog Category
    [`${getShiftType.pending}`]: GET_SHIFT_TYPE.pending,
    [`${getShiftType.fulfilled}`]: GET_SHIFT_TYPE.fulfilled,
    [`${getShiftType.rejected}`]: GET_SHIFT_TYPE.rejected,
    // GET Blog Category BY ID
    [`${getShiftTypeById.pending}`]: GET_SHIFT_TYPE_BY_ID.pending,
    [`${getShiftTypeById.fulfilled}`]: GET_SHIFT_TYPE_BY_ID.fulfilled,
    [`${getShiftTypeById.rejected}`]: GET_SHIFT_TYPE_BY_ID.rejected,
  },
});

export default ShiftTypeSlice.reducer;
