import { createSlice } from '@reduxjs/toolkit';
import { getHome, getFeed } from '../thunk/get/getHome.thunk';
import { postPost } from '../thunk/post/postPost.thunk';
import { postPraise } from '../thunk/post/postPraise.thunk';
import { initialState } from './initialState';
import { GET_HOME } from './getSlices/getHome.get';
import { GET_FEED } from './getSlices/getFeed.get';
import { POST_POST } from './postSlices/postPost.get';
import { POST_PRAISE } from './postSlices/postPraise.get';

export const HomeSlice = createSlice({
  name: 'HomeSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET
    [`${getHome.pending}`]: GET_HOME.pending,
    [`${getHome.fulfilled}`]: GET_HOME.fulfilled,
    [`${getHome.rejected}`]: GET_HOME.rejected,

    // GET FEED
    [`${getFeed.pending}`]: GET_FEED.pending,
    [`${getFeed.fulfilled}`]: GET_FEED.fulfilled,
    [`${getFeed.rejected}`]: GET_FEED.rejected,

    // POST POST
    [`${postPost.pending}`]: POST_POST.pending,
    [`${postPost.fulfilled}`]: POST_POST.fulfilled,
    [`${postPost.rejected}`]: POST_POST.rejected,

    // POST PRAISE
    [`${postPraise.pending}`]: POST_PRAISE.pending,
    [`${postPraise.fulfilled}`]: POST_PRAISE.fulfilled,
    [`${postPraise.rejected}`]: POST_PRAISE.rejected,
  },
});

export default HomeSlice.reducer;
