import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_LEAVE } from './get/getLeave.get';
import { getLeave } from '../thunk/get/getLeave.thunk';
import { GET_LEAVE_BY_ID } from './get/getLeaveById.get';
import { getLeaveById } from '../thunk/get/getLeaveById.thunk';
import { postLeave } from '../thunk/post/postLeave.thunk';
import { putLeave } from '../thunk/put/putLeave.thunk';
import { PUT_LEAVE } from './put/putLeave.put';
import { POST_LEAVE } from './post/postLeave.post';
import { getCheckDates } from '../thunk/get/getCheckDates.thunk';
import { GET_CHECK_DATES } from './get/getCheckDates';
import { getLeaveBalance } from '../thunk/get/getLeaveBalane.thunk';
import { GET_LEAVE_BALANCE } from './get/getLeaveBalace';
import { getLeaveByEmpId } from '../thunk/get/getLeaveyEmpId.thunk';
import { GET_LEAVE_BY_EMP_ID } from './get/getLeaveByEmpId.get';

export const LeaveSlice = createSlice({
  name: 'LeaveSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET
    [`${getLeave.pending}`]: GET_LEAVE.pending,
    [`${getLeave.fulfilled}`]: GET_LEAVE.fulfilled,
    [`${getLeave.rejected}`]: GET_LEAVE.rejected,
    // GET by id
    [`${getLeaveById.pending}`]: GET_LEAVE_BY_ID.pending,
    [`${getLeaveById.fulfilled}`]: GET_LEAVE_BY_ID.fulfilled,
    [`${getLeaveById.rejected}`]: GET_LEAVE_BY_ID.rejected,
    // GET by emp id
    [`${getLeaveByEmpId.pending}`]: GET_LEAVE_BY_EMP_ID.pending,
    [`${getLeaveByEmpId.fulfilled}`]: GET_LEAVE_BY_EMP_ID.fulfilled,
    [`${getLeaveByEmpId.rejected}`]: GET_LEAVE_BY_EMP_ID.rejected,
    // clear state => GET by id
    [`${getLeaveById.pending}`]: GET_LEAVE_BY_ID.pending,
    [`${getLeaveById.fulfilled}`]: GET_LEAVE_BY_ID.fulfilled,
    [`${getLeaveById.rejected}`]: GET_LEAVE_BY_ID.rejected,
    // GET CHECK DATES
    [`${getCheckDates.pending}`]: GET_CHECK_DATES.pending,
    [`${getCheckDates.fulfilled}`]: GET_CHECK_DATES.fulfilled,
    [`${getCheckDates.rejected}`]: GET_CHECK_DATES.rejected,
    // GET LEAVE BALANCE
    [`${getLeaveBalance.pending}`]: GET_LEAVE_BALANCE.pending,
    [`${getLeaveBalance.fulfilled}`]: GET_LEAVE_BALANCE.fulfilled,
    [`${getLeaveBalance.rejected}`]: GET_LEAVE_BALANCE.rejected,
    // post
    [`${postLeave.pending}`]: POST_LEAVE.pending,
    [`${postLeave.fulfilled}`]: POST_LEAVE.fulfilled,
    [`${postLeave.rejected}`]: POST_LEAVE.rejected,
    // put
    [`${putLeave.pending}`]: PUT_LEAVE.pending,
    [`${putLeave.fulfilled}`]: PUT_LEAVE.fulfilled,
    [`${putLeave.rejected}`]: PUT_LEAVE.rejected,
  },
});

export default LeaveSlice.reducer;
