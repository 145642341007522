const String: any = {
  role: 'Role',
  permission: 'Permission',
  setup_role_permission: 'Setup Role Permission',
  user_info: 'User Info',
  employee: 'Employee',
  employment: 'Employment',
  assets_type: 'Assets Type',
  assets_application: 'Assets Application',
  leave_type: 'Leave Type',
  leave_application: 'Leave Application',
  holiday_list: 'Holiday',
  holiday_type: 'Holiday Type',
  company_document: 'Company Document',
  company_policy: 'Company Policy',
  apply: 'Apply',
  search_by_user_or_email: 'Search by user or email',
  have_balance: 'Have Balance',
  balance: 'Balance',
  kyc_verified: 'KYC Verified',
  filter: 'Filter',
  cancel: 'Cancel',
  export: 'Export',
  status: 'Status',
  show: 'Show',
  order: 'Order',
  asc: 'ASC',
  desc: 'DESC',
  submit: 'Submit',
  create: 'Create',
  user: 'User',
  reset: 'Reset',
  save: 'Save',
  copy: 'Copy',
  csv: 'CSV',
  excel: 'Excel',
  copied: 'Copied',
  to_clipboard: 'to clipboard',
  company: 'Company',
  gender: 'Gender',
  birth_day: 'Birth Date',
  salary: 'Salary',
  start_date: 'Start Date',
  date: 'Date',
  there_are_no_record: 'There are no records found',
  view: 'View',
  invoice: 'Invoice',
  print: 'Print',
  bill_for: 'Bill For',
  issue: 'Issue',
  due: 'Due',
  total: 'Total',
  order_id: 'Order ID',
  amount: 'Amount',
  browser: 'Browser',
  ip: 'IP',
  time: 'Time',
  immence_2022: '2022-immence',
  immence: 'immence',
  administrator: 'Administrator',
  profile: 'Profile',
  account_setting: 'Account Settings',
  login_activity: 'Login Activity',
  sign_out: 'Sign Out',
  required_field: 'Required field',
  dashboard: 'Home',
  user_manage: 'User Manage',
  assets: 'Assets',
  leave: 'Leave',
  holiday: 'Holiday',
  holidays: 'Holidays',
  policy: 'Policy',
  login: 'Login',
  sign_in: 'Sign In',
  forgot_password: 'Forgot Password',
  success: 'Success',
  classic: 'Classic',
  modern: 'Modern',
  organization_meta_id: `Organization Meta Id`,
  Please_enter: 'Please enter',
  name: 'Name',
  description: 'Description',
  is_active: 'Is Active',
  in_active: 'In Active',
  select: 'Select',
  active: 'Active',
  is_delete: 'Is Delete',
  employee_id: 'Employee Id',
  assetsType_id: 'Asset Type Id',
  end_date: 'End Date',
  attachment: 'Attachment',
  remark: 'Remark',
  suspend: 'suspend',
  updated_by: 'Updated By',
  created_by: 'Created By',
  leave_type_id: 'Leave Type Id',
  approved: 'Approved',
  approved_date: 'Approved Date',
  approved_by: 'Approved By',
  reject: 'Reject',
  type: 'Type',
  title: 'Title',
  slug: 'Slug',
  content: 'Content',
  note: 'Notes',
  avatar: 'Avatar',
  first_name: 'First Name',
  middle_name: 'Middle Name',
  last_name: 'Last Name',
  department: 'Department',
  mobile_number: 'Mobile Number',
  whatsapp_mobile: 'WhatsApp Mobile Number',
  parent_mobile: 'Parent Mobile Number',
  aadhaar_card_number: 'Aadhaar Card Number',
  pan_card_number: 'Pan Card Number',
  last_login: 'Last Login',
  intro: 'Intro',
  admin: 'Admin',
  registered_at: 'Registered At',
  term_condition: 'Terms & Condition',
  privacy: 'Privacy',
  help: 'Help',
  current_address: 'Current Address',
  permanent_address: 'Permanent Address',
  address_detail: 'Address Details',
  education: ' Education',
  country: 'Country',
  height: 'Height',
  can_not_login: 'Can not login with credentials',
  reset_password: 'Reset password',
  if_you_forgot_your:
    'If you forgot your password, well, then we’ll email you instructions to reset your password.',
  unable_to_login: ' Unable to login with credentials',
  email: 'Email',
  personal_email: 'Personal Email',
  return_to_login: 'Return to login',
  access_immence_using_email_pass: 'Access immence using your email and password.',
  forget_code: 'Forgot Code?',
  you_can_now_sign_in: 'You can now sign in with your new password',
  welcome_to_immence: 'Welcome to immence',
  delete: 'Delete',
  enter: 'Enter',
  previous: 'Previous',
  employee_detail: 'Employee Details',
  company_email: 'Company Email',
  manager_team_lead: 'Manager/Team Lead',
  thank_you_for_submitting_form: 'Thank you for submitting form',
  edit: 'Edit',
  employee_code: 'Employee Code',
  designation: 'Designation',
  blood_group: 'Blood Group',
  nationality: 'Nationality',
  address_1: 'Address 1',
  address_2: 'Address 2',
  city: 'City',
  state_region: 'State/Region',
  postal_code: 'Postal/Zip Code',
  same_as_permanent_address: 'Same As Permanent Address',
  same_as_above: 'Same as above',
  add: 'Add',
  in: 'In',
  degree: 'Degree',
  permanent: 'Permanent',
  joining_date: 'Joining Date',
  onboarding_date: 'Onboarding Date',
  relieving_date: 'Relieving Date',
  profile_picture: 'Profile Picture',
  passcode: 'Passcode',
  password: 'Password',
  enter_your: 'Enter your',
  next: 'Next',
  uploaded_by: 'Uploaded By',
  file: 'File',
  preview: 'Preview',
  sequence_no: 'Sequence No',
  valid: 'Valid',
  seqNo: 'seqNo',
  action: 'Action',
  team_lead: `Team Lead`,
  delete_confirmation: 'Delete Confirmation',
  are_you_sure_you_want_to_delete_the: ' Are you sure you want to delete the',
  granted: 'Granted',
  current_leave: 'Current Leave',
  for: 'for',
  half: 'Half',
  applied: 'Applied',
  day_type: 'Day Type',
  personal: 'Personal',
  master: 'Master',
  full_day: 'Full Day',
  reason: 'Reason',
  i_want: 'I want to inform',
  code: 'Code',
  serialNumber: 'Serial Number',
  assignee: 'Assignee',
  assignDate: 'Assign Date',
  notes: 'Notes',
  grant: 'Grant',
  month_year: 'Month-Year',
  options: 'options',
  approve: 'Approve',
  count: 'Count',
  grant_leave: 'Grant Leave',
  grant_leaves: 'Grant Leaves',
  back: 'Back',
  required: '* required',
  is_required: 'is required',
  get_new_password: 'Get New Password',
  granted_Leave: 'Granted Leave',
  update: 'Update',
  search: 'Search',
  type_to_search: 'Type to Search',
  optional_leave: 'Optional Leave',
  history: 'History',
  company_drive: 'Company Drive',
  company_training: 'Company Training',
  accessible: 'Accessible',
  to: 'To',
  company_activities: 'Company Activities',
  activities: 'Activities',
  person: 'Person',
  updated_at: 'Updated At',
  contact: 'Contact',
  activity: 'Activity',
  information: 'Information',
  asset: 'Asset',
  not: 'Not',
  avatar_preview: 'Avatar Preview',
  image_preview: 'Image Preview',
  something_went_wrong: 'Something went wrong',
  responsible: 'Responsible',
  link: 'Link',
  day: 'Day',
};

export default String;
