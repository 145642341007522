/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { referenceAdminEndPoint, referenceEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class ReferenceService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${referenceAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${referenceEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${referenceEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${referenceEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${referenceEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${referenceEndPoint}/${id}`);
  }
}

export default new ReferenceService();
