import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_POST_TYPE } from './get/getPostType.get';
import { GET_POST_TYPE_BY_ID } from './get/getPostTypeById.get';
import { getPostType } from '../thunk/get/getPostType.thunk';
import { getPostTypeById } from '../thunk/get/getPostTypeById.thunk';

export const PostTypeSlice = createSlice({
  name: 'PostTypeSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET POST Type
    [`${getPostType.pending}`]: GET_POST_TYPE.pending,
    [`${getPostType.fulfilled}`]: GET_POST_TYPE.fulfilled,
    [`${getPostType.rejected}`]: GET_POST_TYPE.rejected,
    // GET POST Type BY ID
    [`${getPostTypeById.pending}`]: GET_POST_TYPE_BY_ID.pending,
    [`${getPostTypeById.fulfilled}`]: GET_POST_TYPE_BY_ID.fulfilled,
    [`${getPostTypeById.rejected}`]: GET_POST_TYPE_BY_ID.rejected,
  },
});

export default PostTypeSlice.reducer;
