import { createSlice } from '@reduxjs/toolkit';
import { getHoliday, getOptionalHoliday } from '../thunk/get/getHoliday.thunk';
import { initialState } from './initialState';
import { getHolidayById } from '../thunk/get/getHolidayById.thunk';
import { putHoliday } from '../thunk/put/putHoliday.thunk';
import { postHoliday } from '../thunk/post/postHoliday.thunk';
import { deleteHoliday } from '../thunk/delete/deleteHoliday.thunk';
import { GET_HOLIDAY, GET_OPTIONAL_HOLIDAY } from './getSlices/getHoliday.get';
import { GET_HOLIDAY_BY_ID } from './getSlices/getHolidayById.get';
import { POST_HOLIDAY } from './postSlices/postHoliday.post';
import { PUT_HOLIDAY } from './putslices/putSlice.put';
import { DELETE_HOLIDAY } from './deleteSlices/deleteHoliday.delete';

export const HolidaySlice = createSlice({
  name: 'HolidaySlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET
    [`${getHoliday.pending}`]: GET_HOLIDAY.pending,
    [`${getHoliday.fulfilled}`]: GET_HOLIDAY.fulfilled,
    [`${getHoliday.rejected}`]: GET_HOLIDAY.rejected,

    // GET Optional Holiday
    [`${getOptionalHoliday.pending}`]: GET_OPTIONAL_HOLIDAY.pending,
    [`${getOptionalHoliday.fulfilled}`]: GET_OPTIONAL_HOLIDAY.fulfilled,
    [`${getOptionalHoliday.rejected}`]: GET_OPTIONAL_HOLIDAY.rejected,

    // GET by ID
    [`${getHolidayById.pending}`]: GET_HOLIDAY_BY_ID.pending,
    [`${getHolidayById.fulfilled}`]: GET_HOLIDAY_BY_ID.fulfilled,
    [`${getHolidayById.rejected}`]: GET_HOLIDAY_BY_ID.rejected,

    // POST
    [`${postHoliday.pending}`]: POST_HOLIDAY.pending,
    [`${postHoliday.fulfilled}`]: POST_HOLIDAY.fulfilled,
    [`${postHoliday.rejected}`]: POST_HOLIDAY.rejected,

    // PUT
    [`${putHoliday.pending}`]: PUT_HOLIDAY.pending,
    [`${putHoliday.fulfilled}`]: PUT_HOLIDAY.fulfilled,
    [`${putHoliday.rejected}`]: PUT_HOLIDAY.rejected,

    // DELETE
    [`${deleteHoliday.pending}`]: DELETE_HOLIDAY.pending,
    [`${deleteHoliday.fulfilled}`]: DELETE_HOLIDAY.fulfilled,
    [`${deleteHoliday.rejected}`]: DELETE_HOLIDAY.rejected,
  },
});

export default HolidaySlice.reducer;
