import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginPath } from 'src/utils/const';
import { logoutUser } from 'src/redux/auth/thunk/post/logout.thunk';
import { enqueueSnackbar } from 'notistack';
import leaveServices from '../../services/leave.services';

// export const getLeave = createAsyncThunk('get-company-leave', async (payload: any, thunkAPI) => {
//   try {
//     const response = await leaveServices.get(payload);
//     return response;
//   } catch (error) {
//     if (error.response.status === 401 || error.response.status === 403) {
//       localStorage.removeItem('accessToken');
//       window.location.href = loginPath;
//       thunkAPI.dispatch(logoutUser());
//     }
//     return thunkAPI.rejectWithValue(error);
//   }
// });

// eslint-disable-next-line consistent-return
export const getLeave = createAsyncThunk('get-company-leave', async (payload: any, thunkAPI) => {
  try {
    const response = await leaveServices.get(payload.queryParams);
    if (
      response?.data?.code === 200 &&
      response?.data?.data?.leave &&
      response?.data?.data?.leave?.length
    ) {
      const stateData: any = thunkAPI.getState();
      const getChatStateData: any = stateData?.leave?.get;
      const previousData = getChatStateData.leaveList;
      let data: any = {};
      if (payload.isPagination) {
        data = {
          currentPage: response?.data?.data?.currentPage,
          pageLimit: response?.data?.data?.pageLimit,
          totalItems: response?.data?.data?.totalItems,
          totalPages: response?.data?.data?.totalPages,
          getGrantLeave: response?.data?.data?.getGrantLeave || [],
          leave: [
            ...previousData,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...response?.data?.data?.leave,
          ],
        };
      } else {
        data = {
          currentPage: response?.data?.data?.currentPage,
          pageLimit: response?.data?.data?.pageLimit,
          totalItems: response?.data?.data?.totalItems,
          totalPages: response?.data?.data?.totalPages,
          getGrantLeave: response?.data?.data?.getGrantLeave || [],
          leave: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...response?.data?.data?.leave,
          ],
        };
      }
      return data;
    }
    return {
      currentPage: response?.data?.data?.currentPage,
      pageLimit: response?.data?.data?.pageLimit,
      totalItems: response?.data?.data?.totalItems,
      totalPages: response?.data?.data?.totalPages,
      getGrantLeave: response?.data?.data?.getGrantLeave || [],
      leave: [],
    };
  } catch (error) {
    if (error.response.data.code === 403) {
      localStorage.setItem('isAdminView', 'false');
      window.location.href = '/home';
      enqueueSnackbar(error.response.data.message || 'Something Went Wrong!', {
        variant: 'warning',
      });
    } else if (error.response.data.code === 401 || error.response.data.code === 400) {
      localStorage.removeItem('accessToken');
      window.location.href = loginPath;
      enqueueSnackbar(error.response.data.message || 'Please Login!', {
        variant: 'error',
      });
      thunkAPI.dispatch(logoutUser());
    }
    return thunkAPI.rejectWithValue(error);
  }
});
