export const initialState = {
  loginState: {
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    isError: false,
    user: false,
    isAuthenticated: false,
    totalItems: '',
  },
  changePasswordState: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    message: '',
  },
  forgotPasswordState: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    message: '',
  },
  setPasswordState: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    message: '',
  },
  adminView: true,
};
