/* eslint-disable class-methods-use-this */
import { colorEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class ColorService {
  get(queryParams: any) {
    return commonAPI.get(`/${colorEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${colorEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${colorEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${colorEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${colorEndPoint}/${id}`);
  }
}

export default new ColorService();
