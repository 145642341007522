/* eslint-disable class-methods-use-this */
import {
  attendanceEndPoint,
  attendanceHistoryEndPoint,
  attendanceRequestEndPoint,
  attendanceStatusEndPoint,
  checkInOutEndPoint,
  importSheetEndPoint,
} from 'src/api/api-end-points';
import { checkIsAdmin } from 'src/utils/helper';
import commonAPI from '../../../api/common';

class AttendanceService {
  get() {
    return commonAPI.get(`/${attendanceEndPoint}/${checkInOutEndPoint}`);
  }

  getList(queryParams: string) {
    if (checkIsAdmin()) {
      return commonAPI.get(
        `/${attendanceEndPoint}/${attendanceRequestEndPoint}/admin${queryParams || ''}`
      );
    }
    return commonAPI.get(`/${attendanceEndPoint}/${attendanceRequestEndPoint}${queryParams || ''}`);
  }

  getByDate(date: any) {
    return commonAPI.get(`/${attendanceEndPoint}?date=${date}`);
  }

  getRequestById(id: any) {
    return commonAPI.get(`/${attendanceEndPoint}/${attendanceRequestEndPoint}/${id}`);
  }

  getAttendanceStatus(queryParams: string) {
    return commonAPI.get(`/${attendanceEndPoint}/${attendanceStatusEndPoint}?${queryParams || ''}`);
  }

  getAttendanceList(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(
        `/${attendanceEndPoint}/${attendanceHistoryEndPoint}/admin${queryParams || ''}`
      );
    }
    return commonAPI.get(`/${attendanceEndPoint}/${attendanceHistoryEndPoint}${queryParams || ''}`);
  }

  addAttendanceLog(data: any, overwriteData = false) {
    return commonAPI.post(
      `/${attendanceEndPoint}/${importSheetEndPoint}?overwriteData=${overwriteData}`,
      data
    );
  }

  post(data: any) {
    return commonAPI.post(`/${attendanceEndPoint}/${checkInOutEndPoint}`, data);
  }

  postRequest(data: any) {
    return commonAPI.post(`/${attendanceEndPoint}/${attendanceRequestEndPoint}`, data);
  }

  putRequest(id: any, data: any) {
    return commonAPI.put(`/${attendanceEndPoint}/${attendanceRequestEndPoint}/${id}`, data);
  }

  putRequestStatus(id: any, data: any) {
    return commonAPI.put(
      `/${attendanceEndPoint}/${attendanceRequestEndPoint}/${attendanceStatusEndPoint}/${id}`,
      data
    );
  }

  deleteRequest(id: any) {
    return commonAPI.delete(`/${attendanceEndPoint}/${attendanceRequestEndPoint}/${id}`);
  }
}

export default new AttendanceService();
