import { createSlice } from '@reduxjs/toolkit';
import { loginUser, setLoginData } from '../thunk/post/login.thunk';
import { initialState } from './initialState';
import { LOGIN } from './post-slices/login.post';
import { changePassword } from '../thunk/post/changePassword.thunk';
import { forgotPassword } from '../thunk/post/forgotPassword.thunk';
import { setPassword } from '../thunk/post/setPassword.thunk';
import { CHANGE_PASSWORD } from './post-slices/changePassword.post';
import { FORGOT_PASSWORD } from './post-slices/forgotPassword.post';
import { SET_PASSWORD } from './post-slices/setPassword.post';

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    viewChanged(state: any, action: any) {
      state.adminView = action.payload;
    },
  },
  extraReducers: {
    // post Login
    [`${loginUser.pending}`]: LOGIN.pending,
    [`${loginUser.fulfilled}`]: LOGIN.fulfilled,
    [`${loginUser.rejected}`]: LOGIN.rejected,

    // set login data
    [`${setLoginData.pending}`]: LOGIN.pending,
    [`${setLoginData.fulfilled}`]: LOGIN.fulfilled,
    [`${setLoginData.rejected}`]: LOGIN.rejected,

    // change password
    [`${changePassword.pending}`]: CHANGE_PASSWORD.pending,
    [`${changePassword.fulfilled}`]: CHANGE_PASSWORD.fulfilled,
    [`${changePassword.rejected}`]: CHANGE_PASSWORD.rejected,

    // forgot password
    [`${forgotPassword.pending}`]: FORGOT_PASSWORD.pending,
    [`${forgotPassword.fulfilled}`]: FORGOT_PASSWORD.fulfilled,
    [`${forgotPassword.rejected}`]: FORGOT_PASSWORD.rejected,

    // set password
    [`${setPassword.pending}`]: SET_PASSWORD.pending,
    [`${setPassword.fulfilled}`]: SET_PASSWORD.fulfilled,
    [`${setPassword.rejected}`]: SET_PASSWORD.rejected,
  },
});

export const { viewChanged }: any = authSlice.actions;
export default authSlice.reducer;
