/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { assetsAdminEndPoint, assetsEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class AssetsService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${assetsAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${assetsEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${assetsEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${assetsEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${assetsEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${assetsEndPoint}/${id}`);
  }
}

export default new AssetsService();
