// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/useOffSetTop';
import { useResponsive } from 'src/hooks/useResponsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import Iconify from 'src/components/iconify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, dispatch as reduxDispatch } from 'src/redux/store';
import { getMaster } from 'src/redux/master/thunk/master.thunk';
import { permissionMasterEndPoint } from 'src/api/api-end-points';
import { isAdminView } from 'src/utils/const';
import { viewChanged } from 'src/redux/auth/slices/auth.slice';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import { getNotification } from 'src/redux/notification/thunk/getNotification.thunk';
import { useNotificationPopups } from 'src/hooks/useNotificationsPopups';
import BirthDayPopup from 'src/sections/@dashboard/home/components/BirthDayPopup';
import PraisePopup from 'src/sections/@dashboard/home/components/PraisePopup';
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state: any) => state.permissions?.get);
  const notificationPopups: any = useNotificationPopups();
  const [isView, setIsView] = useState<boolean>(true);
  const adminView = useSelector((state: any) => state.auth.adminView);
  const notificationData = useSelector((state: any) => state.notification?.get?.notification);
  const notificationPopupData = useSelector((state: any) => state.attendance?.post.notification);
  const birthdayPopupData = useSelector((state: any) => state.notification?.birthdayPopupData);
  const praisePopupData = useSelector((state: any) => state.notification?.praisePopupData);

  useEffect(() => {
    // dispatch(getMaster(permissionMasterEndPoint));
    dispatch(getNotification(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(
    () => {
      const view: any = localStorage.getItem('isAdminView') === 'true';
      setIsView(!!view);
      reduxDispatch(viewChanged(!!view));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdminView]
  );

  const isHome = window.location.pathname === '/home';

  useEffect(() => {
    setTimeout(() => {
      notificationPopups.openNotificationPopups(true);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthdayPopupData.length > 0 || praisePopupData.length > 0]);

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const navigate = useNavigate();
  const location = useLocation();

  const employeeViewClick = () => {
    localStorage.setItem('isAdminView', 'false');
    reduxDispatch(viewChanged(false));
    setIsView(false);
    if (location.pathname.includes('edit')) {
      const currentPath: any = `/${
        location.pathname.split('/').filter((segment) => segment !== '')[0]
      }/${location.pathname.split('/').filter((segment) => segment !== '')[1]}`;

      navigate(`${currentPath}`);
    }
  };

  const adminViewClick = () => {
    localStorage.setItem('isAdminView', 'true');
    reduxDispatch(viewChanged(true));
    setIsView(true);
  };

  const renderContent = (
    <>
      <BirthDayPopup
        openDialog={notificationPopups.birthdayPopup}
        openDialogBox={notificationPopups.openBirthdayPopup}
        closeDialogBox={notificationPopups.closeBirthdayPopup}
      />
      <PraisePopup
        openPraiseDialog={notificationPopups.praisePopup}
        onOpenPraiseDialog={notificationPopups.openPraisePopup}
        onClosePraiseDialog={notificationPopups.closePraisePopup}
      />

      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <LanguagePopover /> */}
        {isHome && isAdmin && adminView && (
          <Box
            onClick={() => employeeViewClick()}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              bgcolor: theme.palette.primary.main,
              padding: '7px',
            }}
          >
            <SvgColor
              src={`/assets/icons/header/${'ic_admin'}.svg`}
              sx={{
                color: theme.palette.background.default,
                width: '16px',
                height: '16px',
              }}
            />
          </Box>
        )}
        {isHome && isAdmin && !adminView && (
          <Box
            onClick={() => adminViewClick()}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              bgcolor: theme.palette.secondary.main,
              padding: '7px',
            }}
          >
            <SvgColor
              src={`/assets/icons/header/${'ic_employee'}.svg`}
              sx={{
                color: theme.palette.background.default,
                width: '16px',
                height: '16px',
              }}
            />
          </Box>
        )}

        <NotificationsPopover />

        {/* <ContactsPopover /> */}

        {/* <SettingsButton /> */}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
