import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../services/auth.service';

export const logoutUser = createAsyncThunk('auth-logout', async (payload, thunkAPI) => {
  try {
    const response = await authService.postLogout();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
