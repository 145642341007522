import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_BLOG } from './get/getBlog.get';
import { GET_BLOG_BY_ID } from './get/getBlogById.get';
import { getBlog } from '../thunk/get/getBlog.thunk';
import { clearBlogById, getBlogById } from '../thunk/get/getBlogById.thunk';

export const BlogSlice = createSlice({
  name: 'BlogSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Blog
    [`${getBlog.pending}`]: GET_BLOG.pending,
    [`${getBlog.fulfilled}`]: GET_BLOG.fulfilled,
    [`${getBlog.rejected}`]: GET_BLOG.rejected,
    // GET Blog  BY ID
    [`${getBlogById.pending}`]: GET_BLOG_BY_ID.pending,
    [`${getBlogById.fulfilled}`]: GET_BLOG_BY_ID.fulfilled,
    [`${getBlogById.rejected}`]: GET_BLOG_BY_ID.rejected,
    // clear Blog  BY ID
    [`${clearBlogById.pending}`]: GET_BLOG_BY_ID.pending,
    [`${clearBlogById.fulfilled}`]: GET_BLOG_BY_ID.fulfilled,
    [`${clearBlogById.rejected}`]: GET_BLOG_BY_ID.rejected,
  },
});

export default BlogSlice.reducer;
