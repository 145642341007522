import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getJob } from '../thunk/get/getJob.thunk';
import { getJobById } from '../thunk/get/getJobById.thunk';
import { GET_JOB } from './get/getJob.get';
import { GET_JOB_BY_ID } from './get/getJobById.get';

export const JobSlice = createSlice({
  name: 'JobSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Blog Category
    [`${getJob.pending}`]: GET_JOB.pending,
    [`${getJob.fulfilled}`]: GET_JOB.fulfilled,
    [`${getJob.rejected}`]: GET_JOB.rejected,
    // GET Blog Category BY ID
    [`${getJobById.pending}`]: GET_JOB_BY_ID.pending,
    [`${getJobById.fulfilled}`]: GET_JOB_BY_ID.fulfilled,
    [`${getJobById.rejected}`]: GET_JOB_BY_ID.rejected,
  },
});

export default JobSlice.reducer;
