import { useLocation, matchPath } from 'react-router-dom';
import { paths } from '../paths';

// ----------------------------------------------------------------------

type ReturnType = boolean;

export function useActiveLink(path: string, deep = true): ReturnType {
  const { pathname } = useLocation();

  const basePath = paths.PATH_DASHBOARD.root;

  const checkPath = doesPathNameIncludePath(basePath, pathname, path);

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

  // eslint-disable-next-line no-nested-ternary
  return deep ? deepActive : normalActive === false ? checkPath : normalActive;
}

function doesPathNameIncludePath(basePath: string, pathName: string, path: string) {
  const cleanedPathName = pathName?.replace(basePath, '');
  const cleanedPath = path?.replace(basePath, '');

  return path === basePath ? false : cleanedPathName.includes(cleanedPath);
}
