/* eslint-disable class-methods-use-this */
import { departmentMasterEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class DepartmentService {
  get(queryParams: any) {
    return commonAPI.get(`/${departmentMasterEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${departmentMasterEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${departmentMasterEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${departmentMasterEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${departmentMasterEndPoint}/${id}`);
  }
}

export default new DepartmentService();
