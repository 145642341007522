import moment from 'moment';
import { paths } from 'src/routes/paths';

export const EMPLOYEE_CODE_BASE = 'IMC';
export const loginPath = '/auth/login';
export const API_VERSION = `v1`;
export const API_URL = `https://api.immencer.com/`;
export const ORGANIZATION_START_DATE = '01/12/2021';

// date formate
export const DATE_FORMATE_MMM_YYYY = 'MMM yyyy';
export const DATE_FORMATE_YYYY_MM = 'YYYY-MM';
export const DATE_FORMATE_dd_MM_yyyy = 'dd-MM-yyyy';
export const CURRENT_YEAR = moment().year();

// default pagination limits
export const DEFAULT_EMPLOYEE_PAGINATION_LIMIT = '?page=1&limit=50';
export const PAGINATION_LIMIT_FOR_REQUESTS = 30;
export const PAGINATION_LIMIT_FOR_HOLIDAY = 50;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.PATH_DASHBOARD.root;

export const AUTH_LOCAL_STORAGE_TOKEN = localStorage.getItem('accessToken');

export const isAdminView = localStorage.getItem('isAdminView');

// setting
export const organization_start_date = '01/12/2021';

// google map
// export const GOOGLE_MAP_JAVASCRIPT_API_KEY = 'AIzaSyB7LeYR_F6hFeX5upOS7IZ00x8iPfOAkCM';
