/* eslint-disable class-methods-use-this */
import { leaveTypeMasterEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class LeaveTypeService {
  get(queryParams: any) {
    return commonAPI.get(`/${leaveTypeMasterEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${leaveTypeMasterEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${leaveTypeMasterEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${leaveTypeMasterEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${leaveTypeMasterEndPoint}/${id}`);
  }
}

export default new LeaveTypeService();
