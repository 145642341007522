import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getDepartment } from '../thunk/get/getDepartment.thunk';
import { getDepartmentById } from '../thunk/get/getDepartmentById.thunk';
import { GET_DEPARTMENT } from './get/getDepartment.get';
import { GET_DEPARTMENT_BY_ID } from './get/getDepartmentById.get';

export const DepartmentSlice = createSlice({
  name: 'DepartmentSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Blog Category
    [`${getDepartment.pending}`]: GET_DEPARTMENT.pending,
    [`${getDepartment.fulfilled}`]: GET_DEPARTMENT.fulfilled,
    [`${getDepartment.rejected}`]: GET_DEPARTMENT.rejected,
    // GET Blog Category BY ID
    [`${getDepartmentById.pending}`]: GET_DEPARTMENT_BY_ID.pending,
    [`${getDepartmentById.fulfilled}`]: GET_DEPARTMENT_BY_ID.fulfilled,
    [`${getDepartmentById.rejected}`]: GET_DEPARTMENT_BY_ID.rejected,
  },
});

export default DepartmentSlice.reducer;
