/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { topicAdminEndPoint, topicEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class TopicService {
  get(id: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`course/${id}/${topicAdminEndPoint}?page=1&limit=200`);
    }
    return commonAPI.get(`course/${id}/${topicEndPoint}?page=1&limit=200`);
  }

  getById(id: any) {
    return commonAPI.get(`/${topicEndPoint}/${id}`);
  }

  post(id: any, data: any) {
    return commonAPI.post(`course/${id}/${topicEndPoint}`, data);
  }

  put(id: any, cid: any, data: any) {
    return commonAPI.put(`course/${cid}/${topicEndPoint}/${id}`, data);
  }

  delete(id: any, cid: any) {
    return commonAPI.delete(`course/${cid}/${topicEndPoint}/${id}`);
  }
}

export default new TopicService();
