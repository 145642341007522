/* eslint-disable class-methods-use-this */
import { designationMasterEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class DesignationService {
  get(queryParams: any) {
    return commonAPI.get(`/${designationMasterEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${designationMasterEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${designationMasterEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${designationMasterEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${designationMasterEndPoint}/${id}`);
  }
}

export default new DesignationService();
