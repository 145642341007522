import { InitialState } from '../type';

export const GET_PERMISSION_BY_ID = {
  pending: (state: InitialState, action: any) => {
    state.getById.isLoading = true;
    state.getById.isSuccess = false;
    state.getById.isError = false;
  },
  fulfilled: (state: InitialState, action: any) => {
    state.getById.permissionData = action.payload.data.data.permission;
    state.getById.isAdmin = action.payload.data.data.isAdmin;
    state.getById.moduleCategory = action.payload.data.data.moduleCategory;
    state.getById.modules = action.payload.data.data.module;
    state.getById.isLoading = false;
    state.getById.isSuccess = true;
    state.getById.isError = false;
  },
  rejected: (state: InitialState, action: any) => {
    state.getById.isLoading = false;
    state.getById.isSuccess = false;
    state.getById.isError = true;
    state.getById.errorMessage = action?.payload?.data?.message;
  },
};
