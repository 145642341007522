export const initialState = {
  get: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    permissionData: [],
    isAdmin: false,
    modules: [],
    moduleCategory: [],
  },
  getById: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    permissionData: [],
    isAdmin: false,
    modules: [],
    moduleCategory: [],
  },
  put: { isLoading: false, isSuccess: false, isError: false, errorMessage: '', message: '' },
};
