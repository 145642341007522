export const initialState = {
  get: {
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    isError: false,
    bio: false,
    totalItems: '',
  },
  put: {
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    isError: false,
    bio: false,
  },
  isEditBio: false,
};
