import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getSpecification } from '../thunk/get/getSpecification';
import { getSpecificationById } from '../thunk/get/getSpecificationById.thunk';
import { GET_SPECIFICATION } from './getSlices/getSpecification.get';
import { GET_SPECIFICATION_BY_ID } from './getSlices/getSpecificationById.get';

export const SpecificationSlice = createSlice({
  name: 'SpecificationSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET SPECIFICATION
    [`${getSpecification.pending}`]: GET_SPECIFICATION.pending,
    [`${getSpecification.fulfilled}`]: GET_SPECIFICATION.fulfilled,
    [`${getSpecification.rejected}`]: GET_SPECIFICATION.rejected,

    // GET SPECIFICATION BY ID
    [`${getSpecificationById.pending}`]: GET_SPECIFICATION_BY_ID.pending,
    [`${getSpecificationById.fulfilled}`]: GET_SPECIFICATION_BY_ID.fulfilled,
    [`${getSpecificationById.rejected}`]: GET_SPECIFICATION_BY_ID.rejected,
  },
});

export default SpecificationSlice.reducer;
