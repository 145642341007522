export const initialState = {
  get: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    notification: [],
    notificationById: [],
  },
  post: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    message: '',
  },
  birthdayPopupData: [],
  praisePopupData: [],
  praisePopupDataByIndex: {},
};
