/* eslint-disable class-methods-use-this */
import { masterEndPoint } from 'src/api/api-end-points';
import commonAPI from '../../../api/common';

class MasterService {
  get(endPoint: string) {
    return commonAPI.get(`/${masterEndPoint}/${endPoint}`);
  }
}

export default new MasterService();
