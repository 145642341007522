/* eslint-disable import/no-extraneous-dependencies */
import { forwardRef, useEffect, useState } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import Iconify from 'src/components/iconify';
import { Button, Grid, Stack, alpha, useTheme } from '@mui/material';
import { CustomAvatar } from 'src/components/custom-avatar';
import { API_URL, API_VERSION } from 'src/utils/const';
import SvgColor from 'src/components/svg-color';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

type props = {
  openDialog: any;
  openDialogBox: any;
  closeDialogBox: any;
};

export default function BirthDayPopup({ openDialog, openDialogBox, closeDialogBox }: props) {
  const theme = useTheme();
  const birthdayPopupData = useSelector((state: any) => state.notification?.birthdayPopupData);
  const { width, height } = useWindowSize();

  return (
    <Dialog
      id="canvas"
      fullScreen
      open={openDialog}
      onClose={closeDialogBox}
      sx={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflowY: 'auto',
      }}
      TransitionComponent={Transition}
    >
      <Toolbar
        sx={{
          width: '100%',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <IconButton
          edge="end"
          sx={{
            flex: 'end',
            color: theme.palette.grey[500],
            flexShrink: 0,
            width: '50px',
            height: '50px',
          }}
          color="inherit"
          onClick={closeDialogBox}
        >
          <Iconify icon="mingcute:close-fill" />
        </IconButton>
      </Toolbar>

      <Stack
        sx={{
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.secondary.dark,
            fontSize: '35px',
            fontWeight: 700,
            textAlign: 'center',
            mb: 1,
          }}
        >
          Happy Birthday!
        </Typography>

        <Stack sx={{ position: 'relative', flexDirection: { sx: 'column', md: 'row' } }}>
          {birthdayPopupData.map((e: any) => (
            <Grid key={e.id} sx={{ paddingTop: 6, px: 5 }}>
              <IconButton
                sx={{
                  ':& hover': 'none',
                  cursor: 'none',
                  border: `1px solid ${theme.palette.grey[100]}`,
                  marginTop: 6,
                }}
              >
                <CustomAvatar
                  sx={{
                    margin: '0px',
                    width: '140px',
                    height: '140px',
                  }}
                  // eslint-disable-next-line no-nested-ternary
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    e?.toEmployeeAvatar ? `${API_URL}${API_VERSION}/${e?.toEmployeeAvatar}` : ``
                  }
                  alt={`${e?.toEmployeeName}`}
                  name={`${e?.toEmployeeName}`}
                />
                <img
                  key={e.id}
                  src="/assets/icons/home/birthdayCap.png"
                  style={{
                    position: 'absolute',
                    top: -52,
                    left: 7,
                    width: '64px',
                    height: '84px',
                  }}
                  alt=""
                />
              </IconButton>
            </Grid>
          ))}
        </Stack>

        <Typography
          sx={{
            maxWidth: '400px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.grey[800],
            letterSpacing: ' 0.21px',
            fontSize: '21px',
            fontWeight: 400,
            mt: 5,
            textAlign: 'center',
          }}
        >
          {' Wish them an immense amount of joy and laughter on this special day!'}
        </Typography>
      </Stack>
      <Confetti width={width} height={height} />
    </Dialog>
  );
}
