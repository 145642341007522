/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { activitiesAdminEndPoint, activitiesEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class ActivitiesService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${activitiesAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${activitiesEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${activitiesEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${activitiesEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${activitiesEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${activitiesEndPoint}/${id}`);
  }
}

export default new ActivitiesService();
