export const initialState = {
  get: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    list: [],
    status: [],
    attendanceList: [],
    getByDate: [],
    totalItems: '',
  },
  getRequest: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    request: [],
    requestById: [],
    totalItems: '',
    currentDateLog: [],
  },
  post: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    notification: [],
    attendance: {},
  },
  dialogBoxOpen: false,
  workFromHomeDialogBoxOpen: false,
  compOffDialogBoxOpen: false,
  isRequest: false,
  isEdit: false,
  isEditWFH: false,
  isEditCompOff: false,
};
