export const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  employmentStatus: [],
  department: [],
  designation: [],
  bloodGroup: [],
  shiftType: [],
  gender: [],
  nationality: [],
  teamLead: [],
  countries: [],
  states: [],
  city: [],
  holidayType: [],
  assetStatus: [],
  assetType: [],
  leaveType: [],
  leaveDayType: [],
  permission: [],
  leaveStatus: [],
  grantLeave: [],
  zodiacSigns: [],
  maritalStatus: [],
  color: [],
  lastInsertEmpCode: '',
  isOpen: false,
  isCreate: false,
  isEdit: false,
  typeName: '',
  masterName: '',
  
};
