import { InitialState } from '../type';

export const LOGIN = {
  pending: (state: InitialState, action: any) => {
    state.loginState.isLoading = true;
    state.loginState.isSuccess = false;
    state.loginState.isError = false;
    state.loginState.errorMessage = '';
  },
  fulfilled: (state: InitialState, action: any) => {
    state.loginState.user = action?.payload?.data ? action?.payload?.data?.token : action?.payload;
    state.loginState.isAuthenticated = true;
    state.loginState.isLoading = false;
    state.loginState.isSuccess = true;
    state.loginState.isError = false;
    state.loginState.errorMessage = '';
  },
  rejected: (state: InitialState, action: any) => {
    state.loginState.isLoading = false;
    state.loginState.isSuccess = false;
    state.loginState.isError = true;
    state.loginState.errorMessage = action.payload?.response?.data?.message;
  },
};
