import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_BLOG_CATEGORY } from './get/getBlogCategory.get';
import { getBlogCategory } from '../thunk/get/getBlogCategory.thunk';
import { getBlogCategoryById } from '../thunk/get/getBlogCategoryById.thunk';
import { GET_BLOG_CATEGORY_BY_ID } from './get/getBlogCategoryById.get';

export const BlogCategorySlice = createSlice({
  name: 'BlogCategorySlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Blog Category
    [`${getBlogCategory.pending}`]: GET_BLOG_CATEGORY.pending,
    [`${getBlogCategory.fulfilled}`]: GET_BLOG_CATEGORY.fulfilled,
    [`${getBlogCategory.rejected}`]: GET_BLOG_CATEGORY.rejected,
    // GET Blog Category BY ID
    [`${getBlogCategoryById.pending}`]: GET_BLOG_CATEGORY_BY_ID.pending,
    [`${getBlogCategoryById.fulfilled}`]: GET_BLOG_CATEGORY_BY_ID.fulfilled,
    [`${getBlogCategoryById.rejected}`]: GET_BLOG_CATEGORY_BY_ID.rejected,
  },
});

export default BlogCategorySlice.reducer;
