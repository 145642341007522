import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getAssetType } from '../thunk/get/getAssetType.thunk';
import { GET_ASSET_TYPE } from './getSlices/getAssetType.get';

export const AssetTypeSlice = createSlice({
  name: 'AssetTypeSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET ASSET TYPE
    [`${getAssetType.pending}`]: GET_ASSET_TYPE.pending,
    [`${getAssetType.fulfilled}`]: GET_ASSET_TYPE.fulfilled,
    [`${getAssetType.rejected}`]: GET_ASSET_TYPE.rejected,
  },
});

export default AssetTypeSlice.reducer;
