import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_REQUEST_TYPE } from './get/getRequestType.get';
import { GET_REQUEST_TYPE_BY_ID } from './get/getRequestTypeById.get';
import { getRequestTypeById } from '../thunk/get/getRequestTypeById.thunk';
import { getRequestType } from '../thunk/get/getRequestType.thunk';

export const RequestTypeSlice = createSlice({
  name: 'RequestTypeSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Request Type
    [`${getRequestType.pending}`]: GET_REQUEST_TYPE.pending,
    [`${getRequestType.fulfilled}`]: GET_REQUEST_TYPE.fulfilled,
    [`${getRequestType.rejected}`]: GET_REQUEST_TYPE.rejected,
    // GET Request Type BY ID
    [`${getRequestTypeById.pending}`]: GET_REQUEST_TYPE_BY_ID.pending,
    [`${getRequestTypeById.fulfilled}`]: GET_REQUEST_TYPE_BY_ID.fulfilled,
    [`${getRequestTypeById.rejected}`]: GET_REQUEST_TYPE_BY_ID.rejected,
  },
});

export default RequestTypeSlice.reducer;
