/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { subtopicAdminEndPoint, subtopicEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class SubtopicService {
  get(cid: any, tid: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`course/${cid}/${tid}/${subtopicAdminEndPoint}?page=1&limit=200`);
    }
    return commonAPI.get(`course/${cid}/${tid}/${subtopicEndPoint}?page=1&limit=200`);
  }

  getById(cid: any, tid: any) {
    return commonAPI.get(`/${subtopicEndPoint}/${cid}/${tid}`);
  }

  post(cid: any, tid: any, data: any) {
    return commonAPI.post(`course/${cid}/${tid}/${subtopicEndPoint}`, data);
  }

  put(cid: any, tid: any, id: any, data: any) {
    return commonAPI.put(`course/${cid}/${tid}/${subtopicEndPoint}/${id}`, data);
  }

  delete(cid: any, tid: any, id: any) {
    return commonAPI.delete(`course/${cid}/${tid}/${subtopicEndPoint}/${id}`);
  }
}

export default new SubtopicService();
