import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_GRANT_LEAVE } from './get-slices/getGrantLeave.get';
import { getGrantLeave } from '../thunk/get/getGrantLeave.thunk';
import { getGrantLeaveById } from '../thunk/get/getGrantLeaveById.thunk';
import { GET_GRANT_LEAVE_BY_ID } from './get-slices/getGrantLeaveById.get';
import { postGrantLeave } from '../thunk/post/postGrantLeave.thunk';
import { POST_GRANT_LEAVE } from './post-slices/postGrantLeave.post';

export const grantLeaveSlice = createSlice({
  name: 'grantLeaveSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // Get Grant Leave
    [`${getGrantLeave.pending}`]: GET_GRANT_LEAVE.pending,
    [`${getGrantLeave.fulfilled}`]: GET_GRANT_LEAVE.fulfilled,
    [`${getGrantLeave.rejected}`]: GET_GRANT_LEAVE.rejected,

    // Get Grant Leave By ID
    [`${getGrantLeaveById.pending}`]: GET_GRANT_LEAVE_BY_ID.pending,
    [`${getGrantLeaveById.fulfilled}`]: GET_GRANT_LEAVE_BY_ID.fulfilled,
    [`${getGrantLeaveById.rejected}`]: GET_GRANT_LEAVE_BY_ID.rejected,

    // Post Grant Leave
    [`${postGrantLeave.pending}`]: POST_GRANT_LEAVE.pending,
    [`${postGrantLeave.fulfilled}`]: POST_GRANT_LEAVE.fulfilled,
    [`${postGrantLeave.rejected}`]: POST_GRANT_LEAVE.rejected,
  },
});

export default grantLeaveSlice.reducer;
