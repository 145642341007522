import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getActivities } from '../thunk/get/getActivities';
import { getActivitiesById } from '../thunk/get/getActivitiesById.thunk';
import { GET_ACTIVITIES } from './getSlices/getActivities.get';
import { GET_ACTIVITIES_BY_ID } from './getSlices/getActivitiesById.get';

export const ActivitiesSlice = createSlice({
  name: 'ActivitiesSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET ASSETS
    [`${getActivities.pending}`]: GET_ACTIVITIES.pending,
    [`${getActivities.fulfilled}`]: GET_ACTIVITIES.fulfilled,
    [`${getActivities.rejected}`]: GET_ACTIVITIES.rejected,

    // GET ASSET BY ID
    [`${getActivitiesById.pending}`]: GET_ACTIVITIES_BY_ID.pending,
    [`${getActivitiesById.fulfilled}`]: GET_ACTIVITIES_BY_ID.fulfilled,
    [`${getActivitiesById.rejected}`]: GET_ACTIVITIES_BY_ID.rejected,
  },
});

export default ActivitiesSlice.reducer;
