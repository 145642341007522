/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import { employeeAdminEndPoint, employeeEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class EmployeeService {
  get(queryParams: any) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${employeeAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${employeeEndPoint}${queryParams || ''}`);
  }

  getById(id: number) {
    return commonAPI.get(`/${employeeEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${employeeEndPoint}`, data);
  }

  put(id: number, data: any) {
    return commonAPI.put(`/${employeeEndPoint}/${id}`, data);
  }

  delete(id: number) {
    return commonAPI.delete(`/${employeeEndPoint}/${id}`);
  }
}

export default new EmployeeService();
