/* eslint-disable class-methods-use-this */
import { shiftTypeMasterEndPoint } from '../../../api/api-end-points';
import commonAPI from '../../../api/common';

class ShiftTypeService {
  get(queryParams: any) {
    return commonAPI.get(`/${shiftTypeMasterEndPoint}${queryParams || ''}`);
  }

  getById(id: any) {
    return commonAPI.get(`/${shiftTypeMasterEndPoint}/${id}`);
  }

  post(data: any) {
    return commonAPI.post(`/${shiftTypeMasterEndPoint}`, data);
  }

  put(id: any, data: any) {
    return commonAPI.put(`/${shiftTypeMasterEndPoint}/${id}`, data);
  }

  delete(id: any) {
    return commonAPI.delete(`/${shiftTypeMasterEndPoint}/${id}`);
  }
}

export default new ShiftTypeService();
