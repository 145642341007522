/* eslint-disable class-methods-use-this */
import { checkIsAdmin } from 'src/utils/helper';
import {
  adminEndPoint,
  checkDatesEndPoint,
  leaveAdminEndPoint,
  leaveBalanceEndPoint,
  leaveEndPoint,
  leaveHistoryEndPoint,
} from 'src/api/api-end-points';
import commonAPI from '../../../api/common';

class LeaveService {
  get(queryParams: string) {
    if (checkIsAdmin()) {
      return commonAPI.get(`/${leaveAdminEndPoint}${queryParams || ''}`);
    }
    return commonAPI.get(`/${leaveEndPoint}${queryParams || ''}`);
  }

  getByEmpId(id: any) {
    return commonAPI.get(
      `/${leaveEndPoint}/${leaveHistoryEndPoint}/${adminEndPoint}?employeeId=${id}`
    );
  }

  getById(id: number) {
    return commonAPI.get(`/${leaveEndPoint}/${id}`);
  }

  getCheckDate(queryParams: string) {
    return commonAPI.get(`/${leaveEndPoint}/${checkDatesEndPoint}${queryParams || ''}`);
  }

  getLeaveBalance(queryParams: string) {
    return commonAPI.get(`/${leaveEndPoint}/${leaveBalanceEndPoint}${queryParams || ''}`);
  }

  post(data: any) {
    if (checkIsAdmin()) {
      return commonAPI.post(`/${leaveAdminEndPoint}`, data);
    }
    return commonAPI.post(`/${leaveEndPoint}`, data);
  }

  put(id: number, data: any) {
    if (checkIsAdmin()) {
      return commonAPI.put(`/${leaveAdminEndPoint}/${id}`, data);
    }
    return commonAPI.put(`/${leaveEndPoint}/${id}`, data);
  }

  delete(leaveId: number) {
    return commonAPI.delete(`/${leaveEndPoint}/${leaveId}`);
  }
}

export default new LeaveService();
