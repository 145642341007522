export const initialState = {
  get: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    leaveById: [],
    leaveList: [],
    leaveDetails: {},
    employeeLeave: [],
    totalItems: '',
    grantLeave: {},
    checkDates: '',
    leaveBalance: '',
    totalPages: '',
    currentPage: '',
  },
  getLeaveByEmpId: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    empLeaveData: [],
  },
  post: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    message: '',
  },
  put: { isLoading: false, isSuccess: false, isError: false, errorMessage: '', message: '' },
  delete: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
};
