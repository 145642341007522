import { useState } from 'react';
import {
  storeBirthDayPopup,
  storePraisePopup,
} from 'src/redux/notification/slices/notification.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export const useNotificationPopups = () => {
  const dispatch = useDispatch();
  const birthdayPopupData = useSelector((state: any) => state.notification?.birthdayPopupData);
  const praisePopupData = useSelector((state: any) => state.notification?.praisePopupData);
  const [birthdayPopup, setBirthdayPopup] = useState<boolean>(false);
  const [praisePopup, setPraisePopup] = useState<boolean>(false);
  const [praiseIndex, setPraiseIndex] = useState<number>(0);

  const openBirthdayPopup = () => {
    setBirthdayPopup(true);
  };

  const closeBirthdayPopup = (data?: any) => {
    setBirthdayPopup(false);
    setTimeout(() => {
      openNotificationPopups(false, true);
    }, 500);
  };

  const openPraisePopup = () => {
    setPraisePopup(true);
  };

  const closePraisePopup = () => {
    setPraisePopup(false);
    openNotificationPopups(false, true);
  };

  // eslint-disable-next-line consistent-return
  const openNotificationPopups = (isBirthDayOpen?: boolean, isPraiseOpen?: boolean) => {
    if (birthdayPopupData.length === 0 && praisePopupData.length === 0) {
      return null;
    }

    if (birthdayPopupData.length > 0 && !isPraiseOpen && isBirthDayOpen) {
      saveBirthDayData(birthdayPopupData);
      setTimeout(() => {
        openBirthdayPopup();
      }, 1000);
    }

    if (birthdayPopupData.length === 0) {
      isPraiseOpen = true;
    }

    if (praisePopupData.length > 0 && isPraiseOpen && praiseIndex < praisePopupData.length) {
      savePraiseData(praisePopupData, praiseIndex);
      openPraisePopup();
      setPraiseIndex((prev) => prev + 1);
    }

    // return null;
  };

  const saveBirthDayData = (data: any) => {
    const birthdayData = data.filter((item: any) => item.notificationTypeName === 'Birthday');
    dispatch(storeBirthDayPopup(birthdayData));
  };

  const savePraiseData = (data: any, index: any) => {
    const praiseData = data.filter((item: any) => item.notificationTypeName === 'Praise');
    const payload = { data: praiseData, dataByIndex: { ...praiseData[praiseIndex] } };
    dispatch(storePraisePopup(payload));
  };

  return {
    praisePopup,
    birthdayPopup,
    openNotificationPopups,
    saveBirthDayData,
    savePraiseData,
    openPraisePopup,
    closePraisePopup,
    closeBirthdayPopup,
    openBirthdayPopup,
  };
};
