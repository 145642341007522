import { createAsyncThunk } from '@reduxjs/toolkit';

export const saveEmployeePayload = createAsyncThunk(
  'save-employee-payload',
  async (payload: any) => {
    try {
      return payload;
    } catch (error) {
      return error;
    }
  }
);
