import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getWorkType } from '../thunk/get/getWorkType.thunk';
import { GET_WORK_TYPE } from './get/getWorkType.get';

export const WorkTypeSlice = createSlice({
  name: 'WorkTypeSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET job Type
    [`${getWorkType.pending}`]: GET_WORK_TYPE.pending,
    [`${getWorkType.fulfilled}`]: GET_WORK_TYPE.fulfilled,
    [`${getWorkType.rejected}`]: GET_WORK_TYPE.rejected,
  },
});

export default WorkTypeSlice.reducer;
