import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { GET_BLOG_TAG } from './get/getBlogTag.get';
import { getBlogTag } from '../thunk/get/getBlog.thunk';

export const BlogTagSlice = createSlice({
  name: 'BlogTagSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET Blog Tag
    [`${getBlogTag.pending}`]: GET_BLOG_TAG.pending,
    [`${getBlogTag.fulfilled}`]: GET_BLOG_TAG.fulfilled,
    [`${getBlogTag.rejected}`]: GET_BLOG_TAG.rejected,
  },
});

export default BlogTagSlice.reducer;
