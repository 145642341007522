import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getDocument } from '../thunk/get/getDocument';
import { getDocumentById } from '../thunk/get/getDocumentById.thunk';
import { GET_DOCUMENT } from './getSlices/getDocument.get';
import { GET_DOCUMENT_BY_ID } from './getSlices/getDocumentById.get';

export const DocumentSlice = createSlice({
  name: 'DocumentSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET ASSETS
    [`${getDocument.pending}`]: GET_DOCUMENT.pending,
    [`${getDocument.fulfilled}`]: GET_DOCUMENT.fulfilled,
    [`${getDocument.rejected}`]: GET_DOCUMENT.rejected,

    // GET ASSET BY ID
    [`${getDocumentById.pending}`]: GET_DOCUMENT_BY_ID.pending,
    [`${getDocumentById.fulfilled}`]: GET_DOCUMENT_BY_ID.fulfilled,
    [`${getDocumentById.rejected}`]: GET_DOCUMENT_BY_ID.rejected,
  },
});

export default DocumentSlice.reducer;
