import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getTopic } from '../thunk/get/getTopic';
import { getTopicById } from '../thunk/get/getTopicById.thunk';
import { GET_TOPIC } from './getSlices/getTopic.get';
import { GET_TOPIC_BY_ID } from './getSlices/getTopicById.get';

export const TopicSlice = createSlice({
  name: 'TopicSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET TOPIC
    [`${getTopic.pending}`]: GET_TOPIC.pending,
    [`${getTopic.fulfilled}`]: GET_TOPIC.fulfilled,
    [`${getTopic.rejected}`]: GET_TOPIC.rejected,

    // GET TOPIC BY ID
    [`${getTopicById.pending}`]: GET_TOPIC_BY_ID.pending,
    [`${getTopicById.fulfilled}`]: GET_TOPIC_BY_ID.fulfilled,
    [`${getTopicById.rejected}`]: GET_TOPIC_BY_ID.rejected,
  },
});

export default TopicSlice.reducer;
