/* eslint-disable import/no-named-as-default */
import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './auth/slices/auth.slice';
import masterSlice from './master/slices/master.slice';
import employeeSlice from './employee/slices/employee.slice';
import bioSlice from './bio/slices/bio.slice';
import assetsSlice from './assets/slices/assets.slice';
import assetTypeSlice from './assetsType/slices/assetType.slice';
import documentSlice from './document/slices/document.slice';
import driveSlice from './drive/slices/drive.slice';
import policySlice from './policy/slices/policy.slice';
import activitiesSlice from './activities/slices/activities.slice';
import grantLeaveSlice from './grantLeave/slices/grantLeave.slice';
import leaveSlice from './leave/slices/leave.slice';
import holidaySlice from './holiday/slices/holiday.slice';
import courseSlice from './course/slices/course.slice';
import specificationSlice from './specification/slices/specification.slice';
import topicSlice from './topic/slices/topic.slice';
import subtopicSlice from './subtopic/slices/subtopic.slice';
import referenceSlice from './reference/slices/reference.slice';
import exerciseSlice from './exercise/slices/exercise.slice';
import attendanceSlice from './attendance/slices/attendance.slice';
import permissionSlice from './permission/slices/permission.slice';
import homeSlice from './home/slices/home.slice';
import notificationSlice from './notification/slices/notification.slice';
import bankSlice from './bank/slices/bank.slice';
import salarySlipSlice from './salarySlip/slices/salarySlip.slice';
import shiftTypeSlice from './shiftType/slices/shiftType.slice';
import requestTypeSlice from './requestType/slices/requestType.slice';
import colorSlice from './color/slices/color.slice';
import postTypeSlice from './postType/slices/postType.slice';
import employmentStatusSlice from './employmentStatus/slices/employmentStatus.slice';
import blogStatusSlice from './blogStatus/slices/blogStatus.slice';
import designationSlice from './designation/slices/designation.slice';
import departmentSlice from './department/slices/department.slice';
import holidayTypeSlice from './holidayType/slices/holidayType.slice';
import leaveTypeSlice from './leaveType/slices/leaveType.slice';
import jobSlice from './job/slices/job.slice';
import jobTypeSlice from './jobType/slices/jobType.slice';
import workTypeSlice from './workType/slices/workType.slice';
import blogCategorySlice from './blogCategory/slices/blogCategory.slice';
import blogSlice from './blog/slices/blog.slice';
import blogTagSlice from './blogTag/slices/blogTag.slice';

const rootReducer = combineReducers({
  master: masterSlice,
  auth: authSlice,
  employee: employeeSlice,
  bio: bioSlice,
  assets: assetsSlice,
  assetType: assetTypeSlice,
  drive: driveSlice,
  document: documentSlice,
  policy: policySlice,
  activities: activitiesSlice,
  grantLeave: grantLeaveSlice,
  leave: leaveSlice,
  holiday: holidaySlice,
  course: courseSlice,
  specification: specificationSlice,
  topic: topicSlice,
  subtopic: subtopicSlice,
  reference: referenceSlice,
  exercise: exerciseSlice,
  attendance: attendanceSlice,
  permissions: permissionSlice,
  home: homeSlice,
  notification: notificationSlice,
  bank: bankSlice,
  salarySlip: salarySlipSlice,
  blog: blogSlice,
  blogTag: blogTagSlice,
  blogCategory: blogCategorySlice,
  shiftType: shiftTypeSlice,
  requestType: requestTypeSlice,
  color: colorSlice,
  postType: postTypeSlice,
  employmentStatus: employmentStatusSlice,
  blogStatus: blogStatusSlice,
  designation: designationSlice,
  department: departmentSlice,
  holidayType: holidayTypeSlice,
  leaveType: leaveTypeSlice,
  job: jobSlice,
  jobType: jobTypeSlice,
  workType: workTypeSlice,
});

export default rootReducer;
