import { createSlice } from '@reduxjs/toolkit';
import { GET_NOTIFICATION } from './get/getNotification.get';
import { GET_NOTIFICATION_BY_ID } from './get/getNotificationById.get';
// eslint-disable-next-line import/no-cycle
import { getNotification } from '../thunk/getNotification.thunk';
import { getNotificationById } from '../thunk/getNotificationById.thunk';
import { initialState } from './initialState';
import { InitialState } from './type';

const NotificationSlice = createSlice({
  name: 'Notification',
  initialState,
  reducers: {
    storeBirthDayPopup(state: InitialState, action: any) {
      state.birthdayPopupData = action.payload;
    },
    storePraisePopup(state: InitialState, action: any) {
      state.praisePopupData = action.payload.data;
      state.praisePopupDataByIndex = action.payload.dataByIndex;
    },
  },
  extraReducers: {
    // GET
    [`${getNotification.pending}`]: GET_NOTIFICATION.pending,
    [`${getNotification.fulfilled}`]: GET_NOTIFICATION.fulfilled,
    [`${getNotification.rejected}`]: GET_NOTIFICATION.rejected,
    // GET by ID
    [`${getNotificationById.pending}`]: GET_NOTIFICATION_BY_ID.pending,
    [`${getNotificationById.fulfilled}`]: GET_NOTIFICATION_BY_ID.fulfilled,
    [`${getNotificationById.rejected}`]: GET_NOTIFICATION_BY_ID.rejected,
  },
});

export const { storeBirthDayPopup, storePraisePopup }: any = NotificationSlice.actions;
export default NotificationSlice.reducer;
