import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { getCourse } from '../thunk/get/getCourse';
import { getCourseById } from '../thunk/get/getCourseById.thunk';
import { GET_COURSE } from './getSlices/getCourse.get';
import { GET_COURSE_BY_ID } from './getSlices/getCourseById.get';

export const CourseSlice = createSlice({
  name: 'CourseSlice',
  initialState,
  reducers: <any>{},
  extraReducers: {
    // GET ASSETS
    [`${getCourse.pending}`]: GET_COURSE.pending,
    [`${getCourse.fulfilled}`]: GET_COURSE.fulfilled,
    [`${getCourse.rejected}`]: GET_COURSE.rejected,

    // GET ASSET BY ID
    [`${getCourseById.pending}`]: GET_COURSE_BY_ID.pending,
    [`${getCourseById.fulfilled}`]: GET_COURSE_BY_ID.fulfilled,
    [`${getCourseById.rejected}`]: GET_COURSE_BY_ID.rejected,
  },
});

export default CourseSlice.reducer;
